.namedrop .vodiapicker {
  display: none;
}

.namedrop #a {
  padding-left: 0px;
}

.namedrop #a img,
.namedrop .btn-select img {
  width: 22px;
}

.namedrop #a li {
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
  list-style: none;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.namedrop #a li:hover {
  background-color: #f4f3f3;
  border-radius: 10px;
  color: #000;
}

.namedrop #a li img {
  margin: 3px;
  width: 27px;
}

.namedrop #a li span,
.btn-select li span {
  margin-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
button.btn-select:after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  margin: 0;
  height: auto;
  transform: translateY(-50%);
  right: 10px;
  font-size: 20px;
  line-height: normal;
}
/* item list */

.namedrop .b {
  display: none;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #a7b9d3;
  padding: 8px;
  z-index: 1;
}

.namedrop .open {
  display: show !important;
}

.namedrop .btn-select {
  width: 150px !important;
  height: 34px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 350px;
  height: auto;
  background-color: #14161a;
  border: 1px solid #ffffff4a;
  padding: 10px;
  border-radius: 9px;
  color: #fff;
}
.namedrop .btn-select li {
  list-style: none;
  float: left;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
}

.namedrop .btn-select:hover li {
  margin-left: 0px;
}

.namedrop .btn-select:focus {
  outline: none;
}

.namedrop .lang-select {
  margin-left: 0;
  position: relative;
  margin-right: 20px;
}
.filter_dropdown {
  display: flex;
  align-items: center;
}