/* ------------------------ Variable Define -------------------------------- */

:root {
    --primary-color-100: #a93919;
    --primary-color-200: #a94a20;
    --primary-color-300: #f15223;
    --primary-color-400: #f4754f;
    --primary-color-500: #f7977b;
    --primary-color-600: #fbcbbd;

    --system-color-100: #14161a;
    --system-color-200: #191b20;
    --system-color-300: #1d2027;
    --system-color-400: #33343b;
    --system-color-500: #808191;
    --system-color-600: #ffffff;

    --secondary-danger-100: #ee3131;
    --secondary-danger-200: #470f0f;
    --secondary-danger-300: #300a0a;

    --secondary-success-100: #36e25b;
    --secondary-success-200: #10441b;
    --secondary-success-300: #0b2d12;

    --secondary-warning-100: #ffd335;
    --secondary-warning-200: #4c3f10;
    --secondary-warning-300: #332a0b;

    --primary-icon-filter-color: brightness(0) saturate(100%) invert(100%)
        sepia(100%) saturate(100%) hue-rotate(0deg) brightness(100%)
        contrast(100%);
    --primary-close-img-filter-color: none;
}
[data-theme='light'] {
    --primary-color-100: #a93919;
    --primary-color-200: #d94a20;
    --primary-color-300: #f15223;
    --primary-color-400: #f4754f;
    --primary-color-500: #f7977b;
    --primary-color-600: #fbcbbd;

    --system-color-100: #f4f4f5;
    --system-color-200: #fafafa;
    --system-color-300: #ffffff;
    --system-color-400: #e9e9ed;
    --system-color-500: #a2a2a9;
    --system-color-600: #2e2f3c;

    --secondary-danger-100: #e33f3f;
    --secondary-danger-200: #f4b2b2;
    --secondary-danger-300: #f9d9d9;

    --secondary-success-100: #10c538;
    --secondary-success-200: #9fe8af;
    --secondary-success-300: #cff3d7;

    --secondary-warning-100: #eba900;
    --secondary-warning-200: #f7dd99;
    --secondary-warning-300: #fbeecc;

    --primary-icon-filter-color: none;
    --primary-close-img-filter-color: invert(100%) sepia(79%) saturate(2476%)
        hue-rotate(86deg) brightness(118%) contrast(119%);
}

/* ------------------------ Variable Define -------------------------------- */

/* webkit browsers */

/* ------------------------------ scrollbar css ----------------------------- */

* {
    -webkit-font-smoothing: antialiased;
    -moz-os-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
    vertical-align: baseline;
}

html,
body {
    width: 100%;
    height: 100%;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    outline: 0;
    overflow-x: hidden !important;
    color: var(--system-color-600);
    background-color: var(--system-color-200);
    font-size: 14px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    padding: 0;
    margin: 0;
}

h1 {
    font-size: 24px;
    font-weight: 600;
}

h2 {
    font-size: 60px;
    line-height: 90px;
    font-weight: 600;
}

h3 {
    font-size: 45px;
    line-height: 70px;
}

h4 {
    font-size: 35px;
    line-height: 52px;
}

h5 {
    font-size: 30px;
    line-height: 45px;
}

h6 {
    font-size: 25px;
    line-height: 38px;
}

p {
    font-size: 14px;
    font-weight: 600;
}

.fw-400 {
    font-weight: 400 !important;
}
.fw-450 {
    font-weight: 450 !important;
}
.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700;
}
.fs-13 {
    font-size: 13px !important;
}

ul {
    list-style: none;
}

a:hover,
a {
    text-decoration: none;
    color: var(--system-color-600);
}

button:focus {
    outline: 0 !important;
}

.v-center {
    align-self: center;
    align-items: center;
}

button {
    position: relative;
    overflow: hidden;
}

.lnk {
    position: relative;
    overflow: hidden;
}

.animation,
.circle,
.circle2,
.clients-logo,
.custom-btn,
.dropdown-toggle,
.hoshd,
.hover-scale img,
.icon-set,
.isotope_item,
.link-hover,
.main-header,
.main-header.sticky,
.navbar,
.s-block,
.sticky,
.sub-menu-sec a,
button,
input {
    -webkit-transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
    -o-transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
    transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}

#root {
    width: 100%;
    height: 100%;
}

#main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.no-shadow {
    box-shadow: none !important;
}

.navbar-brand img {
    width: 140px;
}
.navbar-brand span {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
}
.filter_dropdown .custom_btn {
    font-size: 14px;
    padding: 12px 33px;
}

/* Cede.store weird z-indexes hotfix */
header {
    z-index: 1500;
}

.modal-backdrop {
    z-index: 1750;
}

.modal {
    z-index: 2000;
}
/* Cede.store weird z-indexes hotfix */

header .logo_bar {
    display: flex;
    align-items: center;
}

header .logo_bar form {
    position: relative;
}

header .logo_bar button {
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
}

header .logo_bar form input {
    background-color: var(--system-color-100);
    border: 0;
    padding: 10px 10px 10px 45px;
    font-size: 14px;
    margin-right: 0;
}

.navbar-nav .nav-link {
    font-size: 14px;
    color: var(--system-color-600);
    padding: 6px 16px !important;
    transition: 0.3s;
    border-radius: 20px;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link.active {
    color: var(--primary-color-300);
    transition: 0.3s;
    background: var(--system-color-100);
}

.DropDownProfile:focus span,
.virtuPoolSaving,
.Avg_Boost_Amount {
    color: var(--primary-color-300);
}

.cellBlocks {
    color: var(--system-color-600);
}

.filterbar {
    margin-left: 20px;
}

.navbar,
.AddLiquidity_Head {
    border-bottom: 1px solid var(--system-color-400);
}

.dropdown_block_size {
    width: 165px;
    max-width: 180px;
}
.sec_ad_desc {
    color: #000;
}
.network_Header {
    max-width: 65px !important;
}
.header_block {
    max-width: 1185px !important;
}
.namedrop .btn-select {
    width: 175px !important;
    background-color: var(--system-color-100);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 1px solid var(--system-color-400);
}
.namedrop button.btn-select:after {
    display: none;
}
.namedrop .btn-select li span,
.network_Header i {
    color: var(--system-color-600);
}
.namedrop .btn-select.error_btn li span,
.namedrop .btn-select.error_btn i {
    color: var(--primary-color-300);
    font-weight: 700;
}
.namedrop .btn_inside .chain_Info {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 10px;
}
.namedrop .btn_inside .chain_Info p {
    color: var(--system-color-600);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.namedrop .btn-select img {
    width: 20px;
}
.MoonIcon {
    font-size: 16px;
}
.SunIcon img {
    width: 16px !important;
}

/* Header ends */

/* Main content Area */

.main_content {
    padding: 60px 0;
}
.main_content_Add {
    padding: 0 !important;
}

.inner_area {
    max-width: 80%;
    margin: 0 auto;
}

.inside_information form {
    margin-top: 15px;
}

.inside_information form button {
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
}

.inside_information form {
    position: relative;
}

.inside_document {
    margin-top: 20px;
}

/* Datatable css  */

table {
    border-collapse: separate;
    border-spacing: 0;
}

.dataTables_wrapper,
.dataTable {
    background-color: var(--system-color-300) !important;
    color: var(--system-color-600);
    padding: 0 20px;
    border-radius: 12px;
    /* overflow-x: auto;
  overflow-y: hidden; */
}

.dataTables_wrapper {
    padding: 0px;
}

table.dataTable td {
    border: none;
}

.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_paginate {
    display: none;
}

table.dataTable td,
table.dataTable th {
    color: var(--system-color-600);
    vertical-align: middle;
    padding: 14px 10px;
}

.img_comb {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.img_comb img {
    width: 24px;
    margin-left: -10px;
}

.img_comb img:first-child {
    margin-left: 0;
}
table.dataTable thead th,
.bootToolTip th,
.vrsw_block_amount {
    color: var(--system-color-500) !important;
}

.coin_name {
    display: flex;
    align-items: center;
}

table.dataTable tbody p span {
    color: var(--system-color-500);
    margin-left: 5px;
}

table.dataTable > tbody > tr:hover .coin_name p {
    color: var(--primary-color-300);
    transition: 0.3s;
}

.coin_name p {
    transition: 0.3s;
}

table.dataTable tbody td.active {
    color: var(--secondary-success-100) !important;
}

table.dataTable tbody td.inactive {
    color: var(--secondary-danger-100) !important;
}

.table > :not(:last-child) > :last-child > * {
    border-bottom: 1px solid var(--system-color-400);
}

table.dataTable > tbody > tr {
    transition: 0.3s;
}

table.dataTable > tbody > tr:hover {
    background: var(--system-color-400);
    border-radius: 8px;
    overflow: hidden;
}

table.dataTable > tbody > tr:hover td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

table.dataTable > tbody > tr:hover td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

table.dataTable > tbody > tr td:first-child,
table.dataTable > thead > tr th:first-child {
    padding-left: 30px;
}

/* Trade Page - First State */

.token_block {
    background-color: var(--system-color-300);
    padding: 35px;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    max-width: 40%;
    margin: 0 auto;
}

.flex_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.wallet_info {
    background: var(--system-color-100);
    border-radius: 12px;
    padding: 13px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    min-width: 230px;
}
.swap {
    display: flex;
    flex-direction: column;
}

.swap .wallet_info:nth-child(2) {
    order: 1;
}

.swap .wallet_info:nth-child(1) {
    order: 2;
}

.createPoolAssets {
    display: flex;
    flex-direction: row;
}
.createPoolAssets .wallet_value {
    order: 1;
}

.createPoolAssets .dMenu1 {
    order: 2;
}
.tokenDropDown:hover .createPool {
    background: transparent !important;
    color: var(--system-color-600);
}
.tokenDropDown .createPool {
    width: 200px !important;
}

.swap_func {
    top: 50% !important;
}
.swap_func_disabled {
    opacity: 0.8;
}

.dMenu1 p {
    text-align: right;
    font-size: 12px;
    color: var(--system-color-500);
    padding-top: 2px;
}

.normal_dropdown span img,
.normal_dropdown li span img {
    display: none;
}

.custom_btn {
    border: 1px solid var(--primary-color-300);
    border-radius: 12px;
    display: block;
    padding: 11px 26px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    transition: 0.3s;
}

.custom_btn.disabled {
    border-color: var(--primary-color-300);
}

.custom_btn.opposite,
.multiHops-flex:hover {
    color: var(--primary-color-300);
}

.custom_btn.opposite:hover {
    color: var(--system-color-600);
}

.custom_btn:hover,
button.custom_btn.logout:focus,
.stepBlock_btn.more_margin a:focus span,
.investCancel:focus span {
    transition: 0.3s;
    background-color: var(--primary-color-300);
}

.swap_func span {
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    background-color: var(--primary-color-300);
    border-radius: 6px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 18px;
    outline: 4px solid var(--system-color-400);
}
.swap_func span img,
.plus_swap img {
    filter: none !important;
}
.tokenDropDown {
    background: transparent;
    border: none;
    color: var(--system-color-600);
    /* cursor: pointer; */
}
.filterImage span {
    height: auto !important;
    width: auto !important;
}
.slippage_area img {
    filter: none !important;
}
.filterImage .slippage_title {
    font-weight: 400;
    font-size: 12px;
    color: #fdd249;
}
.filterImage .slippage_area {
    background-color: #322a0e !important;
}
.getToken:focus span,
.stepBlock_btn.more_margin .tokenDropDown:focus,
.stepBlock_btn button.tokenDropDown:focus,
button.custom_btn.btn.btn-secondary.next_value2:focus,
a.custom_btn.btn-secondary.w-100:focus,
button.custom_btn.btn.btn-secondary.next_value.is-get-token:focus,
.confirm_invest:focus,
button.custom_btn.btn.btn-secondary.next_value:focus {
    border: 1px solid rgb(252, 248, 248);
    border-radius: 12px;
}

.tokenDropDown span.custom_btn.pointer.swapTokenButton {
    background-color: var(--primary-color-300);
    color: var(--system-color-600);
}
.tokenDropDown:hover span.custom_btn.pointer.isSwapping,
.tokenDropDown:hover .custom_btn.pointer.isSwapping {
    transition: 0.3s;
    background-color: transparent !important;
}

.Smart_routing {
    margin-bottom: 0 !important;
}

.Native_pool {
    margin-top: 0 !important;
}

/* .select_Token_balance:hover {
  color: white;
} */

/* Connect Wallet Modal */
.walletconnect-modal__base {
    position: absolute !important;
    top: 51% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto !important;
    width: 100% !important;
    max-width: 410px !important;
}
.walletconnect-modal__mobile__toggle a,
.walletconnect-modal__footer a {
    color: #000 !important;
}
.modal-content {
    background-color: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    text-align: center;
    padding: 15px 15px 0 15px;
}

.layout_boxes {
    background: var(--system-color-200);
    mix-blend-mode: normal;
    border-radius: 12px;
    border: 1px solid transparent;
    padding: 20px;
    transition: 0.3s;
}

.layout_boxes:hover {
    border: 1px solid var(--primary-color-300);
}

.wallet_layouts .col-lg-6 {
    padding: 5px;
}

.layout_boxes img {
    margin-bottom: 15px;
}

.modal-header .btn-close {
    color: var(--system-color-600);
    opacity: 1;
    font-size: 25px;
    padding-top: 0;
}

.modal-title {
    font-size: 16px;
    font-weight: 600;
}

.modal-header {
    border-bottom: 0;
    padding-bottom: 0;
}

.btn-close {
    background: none;
}

.spinner_block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 12px;
}

.spinner_block img {
    /* margin-right: 20px; */
    -webkit-animation: spCircRot 3s infinite linear;
    animation: spCircRot 3s infinite linear;
}

@-webkit-keyframes spCircRot {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

@keyframes spCircRot {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes loaderSpinn {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.loader-spine img {
    -webkit-animation: loaderSpinn 3s infinite linear;
    animation: spCircRot 3s infinite linear;
    width: 20px;
    height: 20px;
}
.error_btn,
.namedrop .error_btn {
    border: 1px solid var(--primary-color-300);
    border-radius: 12px;
    padding: 11px;
    color: var(--primary-color-300);
}

.errorLine {
    text-align: center;
    background-color: var(--primary-color-300);
    padding: 1px;
}
.toastWarn,
.boostedToken_img img {
    width: 18px;
}

.toastText {
    color: var(--system-color-600);
    font-size: 18px;
    font-weight: 500;
    align-items: baseline;
}

.curr_info_token h1 {
    color: var(--system-color-600);
    font-size: 18px;
    font-weight: 700;
}

.transactionHash,
.curr_info_token p.tipRow {
    color: var(--system-color-600);
    font-size: 14px;
    font-weight: 500;
    align-items: baseline;
}
.toastDesc {
    color: rgb(151, 148, 148);
    font-size: 13px;
}
.ToastifyWidth .Toastify__toast-container {
    max-width: fit-content !important;
}

.logout {
    background: transparent;
    color: var(--system-color-600);
}

/* Trade Header */

.desc_pop i {
    margin-left: 10px;
}

.desc_pop {
    position: relative;
    cursor: pointer;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-right: 5px;
    /* width: 110px;
  text-overflow: ellipsis;
  overflow: hidden; */
}

.dropdown_info_block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dropdown_info_block_CP {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dropdown_info {
    position: absolute;
    top: 210%;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    background: var(--system-color-300);
    width: 375px;
    right: 0;
    padding: 15px;
    z-index: 10;
}

.dropdown_info.open {
    opacity: 1;
    visibility: visible;
}

.drop_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--system-color-400);
}

.dropdown_info .custom_btn {
    padding: 9px 15px;
    font-size: 16px;
}

.dropdown_info2 {
    position: absolute;
    top: 125%;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    background: var(--system-color-300);
    width: 300px;
    max-height: 400px;
    right: 0;
    padding: 15px;
    z-index: 10;
    overflow: auto;
}

@media (max-width: 400px) {
    .namedrop .dropdown_info2 {
        right: auto;
        left: 0;
        width: 230px;
    }
    .namedrop .dropdown_info2 .chain_Info p {
        font-size: 14px;
    }
}

@media (min-width: 400px) and (max-width: 540px) {
    .namedrop .dropdown_info2 {
        right: auto;
        left: -100px;
        width: 260px;
    }
    .namedrop .dropdown_info2.wide_button {
        right: auto;
        left: 0;
    }
    .namedrop .dropdown_info2 .chain_Info p {
        font-size: 16px;
    }
}

.dropdown_info2.open {
    opacity: 1;
    visibility: visible;
}
.dropdown_info2 .custom_btn {
    padding: 9px 15px;
    font-size: 16px;
}

.drop_desc span {
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
}

.drop_flex p {
    color: var(--system-color-500);
    font-size: 12px;
    text-align: start !important;
}

.drop_flex {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    justify-content: space-between;
}

/* .drop_flex:last-child {
  padding-bottom: 0%;
} */

.small {
    color: var(--system-color-500);
    font-size: 12px;
}

.inside_new_search {
    background-color: #14161b;
    border-radius: 12px;
}
.inside_new_search .AddLdForm {
    border-bottom: 1px solid var(--system-color-400);
}
.inside_new_search .AddLdForm input,
.inside_new_search .AddLdForm input:focus {
    border: none !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.inside_new_search input,
.inside_new_search input:focus {
    border: 1px solid var(--system-color-400) !important;
    background-color: var(--system-color-100) !important;
}

.curr_list .tokenDropDown {
    background: transparent;
    border-radius: 12px;
    padding: 8px 4px;
    font-size: 12px;
    display: inline-block;
    transition: 0.3s;
}

.curr_list .tokenDropDown:hover,
.curr_list .tokenDropDown.active,
.curr_list .tokenDropDown:focus {
    background: var(--primary-color-300);
    transition: 0.3s;
    width: 95% !important;
}

.priceTag:focus {
    background: var(--primary-color-300);
    border-radius: 12px;
}

.curr_list .tokenDropDown img {
    margin: 0 5px;
    vertical-align: middle;
}

.curr_information p {
    margin-bottom: 10px;
}

.curr_list .tokenDropDown img.check {
    opacity: 0;
    transition: 0.3s;
}

.curr_list .tokenDropDown:hover img.check,
.curr_list .tokenDropDown.active img.check {
    opacity: 1;
    transition: 0.3s;
}

.curr_information {
    padding: 0 20px;
    border-bottom: 1px solid var(--system-color-400);
    padding-bottom: 15px;
}

.nav-tabs .nav-link {
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 100px;
    color: var(--system-color-500);
    flex-shrink: 0;
    margin-bottom: 2px;
    margin-right: 10px;
}

.nav-tabs.scrollbar::-webkit-scrollbar {
    height: 5px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
    background: var(--system-color-300);
    border: 1px solid var(--primary-color-300);
    color: var(--system-color-600);
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.liquidityTable::-webkit-scrollbar {
    height: 8px;
    background: #6969692f;
}
.liquidityTable::-webkit-scrollbar-thumb {
    height: 8px;
    background: #dedede52;
    border-radius: 20px;
}

.nav-tabs {
    border-bottom: none;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto !important;
    cursor: grab;
    overflow: hidden;
}

.group_inside p.title {
    font-size: 12px;
    background: var(--system-color-300);
    text-align: center;
    padding: 5px;
    color: var(--system-color-500);
}
.list_div {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    text-align: left;
    border-radius: 12px;
    transition: 0.3s;
}
.list_div:hover,
button.list_div.tokenDropDown:focus {
    transition: 0.3s;
    background-color: var(--system-color-200);
    cursor: pointer;
}
.list_types .tokenActive {
    color: gray;
    opacity: 0.65;
}
.list_types .tokenActive span {
    color: gray;
    font-size: 14px !important;
}
.list_div {
    display: flex;
    align-items: center;
    /* margin-bottom: 20px; */
}

.list_div p {
    margin-bottom: 0;
}

.list_div img {
    margin-right: 12px;
    flex-shrink: 1;
}

.list_div span,
.backToLP {
    color: var(--system-color-500);
}

.scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0;
    background-color: var(--system-color-400);
}

.scrollbar::-webkit-scrollbar {
    width: 5px;
    background-color: var(--system-color-400);
    border-radius: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--primary-color-300);
}

.sel_token {
    max-height: 332px;
}
.form-control:focus {
    background-color: transparent;
    color: var(--system-color-600);
    box-shadow: none;
}

.token_block.routeBig {
    max-width: 100%;
    width: 752px;
    padding-bottom: 0;
}

.saving_block {
    background: var(--system-color-300);
    mix-blend-mode: normal;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.saving_block p {
    color: var(--system-color-500);
    padding: 0 8px;
    font-size: 12px;
}

span.active,
.c-flex-between .active {
    color: var(--secondary-success-100);
}

.wallet_desc {
    /* width: 335px; */
    margin: 0 auto;
}

.virtual_block h1 {
    text-align: center;
    font-size: 14px;
    padding: 20px 0;
    font-weight: 300;
}

.pool_name h2 {
    font-size: 14px;
    line-height: normal;
    margin-left: 7px;
}

.pool_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--system-color-200);
    border: 1px dashed var(--system-color-400);
    border-radius: 12px;
    padding: 20px 12px;
    margin-bottom: 13px;
}

.col.tri {
    padding: 0;
    border: 1px dashed #312f2f;
}
.triangle {
    padding: 12px 12px !important;
    min-height: 62px !important;
}

.pool_info:last-child {
    margin-bottom: 0;
}
.pools_list .inside_document {
    padding-top: 20px;
    padding-bottom: 80px;
}
.virtual_block {
    background: var(--system-color-300);
    mix-blend-mode: normal;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    margin-top: 20px;
    padding: 0px 28px 20px 28px;
}
.virtual_block_glow {
    box-shadow: 0px 0px 10px 0px #f1522369;
}

.pool_value p span {
    color: var(--system-color-500);
}

.pool_value p,
.add_lq p,
.cellBlocks .cellAmount,
.boostModeSize {
    font-size: 12px !important;
}

.display_info_radio {
    background: var(--system-color-200);
    border-radius: 12px 12px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 20px;
}

.toggle-btn {
    width: 56px;
    height: 28px;
    margin: 0;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    background: url('../images/svg/minus.svg') no-repeat 37px center
        var(--system-color-400);
    cursor: pointer;
    -webkit-transition: background-color 0.4s ease-in-out;
    -moz-transition: background-color 0.4s ease-in-out;
    -o-transition: background-color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out;
    cursor: pointer;
}

.toggle-btn.active {
    background: url('../images/svg/check.svg') no-repeat 10px center
        var(--primary-color-300);
}

.toggle-btn.active .round-btn {
    left: 31px;
    background-color: var(--system-color-600);
}

.toggle-btn .round-btn {
    width: 20px;
    height: 20px;
    background-color: var(--system-color-500);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 5px;
    top: 50%;
    margin-top: -10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.toggle-btn .cb-value {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}

.all-three{
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    align-items: flex-end;
}

.inner-left-three{
    display: flex;
    justify-self: flex-start;
    transform: translateY(35px);
}

.inner-three-center{
    max-width: 500px;
    width: 100%;
}

.single-center{
    max-width: 500px;
    width: 100%;
    margin: auto;
}

.block_insides {
    position: relative;
}

.bit_inside p ,.arrowBox p{
    /* background: var(--primary-color-300); */
    border-radius: 12px;
    display: inline-block;
    padding: 11px 25px 11px 12px;
    border: 1px solid var(--primary-color-300);
}
.bit_inside p img {
    margin-right: 6px;
}

.bit_inside.arrowImg {
    position: absolute;
    left: -183px;
    /* top: -60px; */
} 
.bit_inside {
    display: flex;
    align-items: flex-start;
}

.bit_inside p {
    margin-top: -20px;
}

.bit_inside.arrowImg1 {
    align-items: flex-end;
    flex-direction: row-reverse;
    position: absolute;
    right: -193px;
    /* top: 110px; */
}

.bit_inside.arrowImg1 p {
    margin-bottom: -20px;
}

.RecentTx {
    display: flex;
    justify-content: center;
}
.headerTransaction {
    max-height: 168px;
    overflow: auto;
}
.headerTransaction a:hover,
.multiHops-flex:hover {
    background: var(--system-color-200);
    border-radius: 8px;
    overflow: hidden;
}
.headerTransaction a {
    padding-right: 5px;
}
.multiHops-flex {
    padding: 5px;
    cursor: pointer;
}
.filter-close_img {
    filter: var(--primary-close-img-filter-color) !important;
}
.Switch_Network .custom_btn {
    padding: 11px 11px;
    font-size: 14px;
}
.nav_sub_menu {
    position: absolute !important;
    left: 47% !important;
    top: 80% !important;
}
.navbar-collapse .dropdown-menu-dark .dropdown-item:active {
    background: transparent;
}

/* ------------------------------- hover state starts here ------------------------------ */

.wallet_desc {
    position: relative;
}

.token_block .flex_title span,
.addSetting span {
    background: var(--system-color-100);
    border-radius: 12px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.token_block span img {
    height: 16px;
}

.token_block span:hover img,
.SunIcon:hover img {
    filter: brightness(0) saturate(100%) invert(37%) sepia(24%) saturate(6352%)
        hue-rotate(351deg) brightness(103%) contrast(89%);
}

.token_block span img,
.tradeRouteBanner span img {
    filter: var(--primary-icon-filter-color);
}
.tradeRouteBanner span img {
    height: auto !important;
}

.conversion-text {
    margin: 16px 0;
}

.conversion-text h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: var(--system-color-600);
    /* margin-bottom: 5px; */
}

.wallet-desc-2 .dashed-border {
    border: 1px dashed var(--system-color-400);
    margin: 0;
}

.cost-evaluation {
    padding: 16px 0 4px;
}

.cost-evaluation h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--system-color-500);
}

.cost-evaluation h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: var(--system-color-600);
}
.enableMEV {
    color: var(--primary-color-300);
    font-size: 14px;
}
.cancel_Mev {
    background-color: #33343a !important;
    border: 1px solid #33343a !important;
}
.cancel_Mev:hover {
    background-color: var(--primary-color-300) !important;
}
.c-flex-between,
.Additional_bonus,
.exRoutesToken,
.plReserve_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.exRoutesToken_icon {
    height: 25px !important;
    width: 25px !important;
    filter: none !important;
}
.exRoutesToken span {
    font-size: 14px;
    font-weight: 600;
}

.cost-evaluation .c-flex-between {
    margin-bottom: 12px;
}

.routing-tab-wrapper {
    padding: 16px 0;
}

.routing-tab {
    border: 1px solid var(--primary-color-300);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
    cursor: pointer;
}

.routing-tab .first,
.exRoutesToken span {
    display: flex;
    align-items: center;
}

.routing-tab .second {
    padding-right: 13px;
}

.routing-tab .first h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--system-color-600);
    padding-left: 5px;
}

.routing-tab .first .first-inner {
    background: var(--primary-color-300);
    border-radius: 10px;
    padding: 8px 12px;
}

.routing-tab .first .first-inner h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--system-color-600);
}

.cost-savings {
    padding-top: 0;
    /* margin-bottom: 20px; */
}
.tokenBalance {
    /* text-align: end !important; */
    /* margin-left: 5px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tokenBalance_CP {
    text-align: end !important;
    margin-left: 5px;
}
.max_balance {
    margin-left: 5px;
    cursor: pointer;
}
.poolBalance {
    margin-right: 5px;
}

.tradePageSet {
    min-width: 296px !important;
    height: 102px !important;
    radius: 12px !important;
}

.tradePageSet .row {
    height: 31px;
}

.tradeSection2 {
    background: var(--system-color-300) !important;
    border: 1px solid var(--system-color-400) !important;
    border-radius: 12px !important;
}

.youSell {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: var(--system-color-500);
}

.Price_impact_warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
}

/* transaction settings modal starts here*/

.btn-close-bg {
    background: var(--system-color-100);
    border-radius: 12px;
    height: 32px;
    width: 32px;
    border: none;
}

.modal-title-2,
.ld_pool_details .reward_amount {
    font-size: 16px;
    line-height: 24px;
}

.slippage-tolerance-wrapper {
    margin-top: 8px;
}

.slippage-tolerance-heading {
    display: flex;
    align-items: flex-start;
    margin-bottom: 14px;
}

.slippage-tolerance-heading h6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--system-color-500);
}

.slippage-tolerance-heading img {
    padding-left: 4px;
}

.c-modal-sm {
    max-width: 422px;
}

.slippage-tolerance-value {
    display: flex;
    align-items: center;
}

.slippage-tolerance-value .first {
    width: 100%;
    display: flex;
    align-items: center;
}

.slippage-tolerance-value .first input {
    width: calc(100% - 16px);
    background: var(--system-color-100);
    border: 1px solid var(--secondary-warning-100);
    border-radius: 12px;
    min-height: 40px;
}
.slippage-tolerance-value .first span {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: var(--system-color-400);
    width: 60px;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    background: var(--system-color-500);
    border-radius: 12px;
    margin-right: 6px;
    flex-shrink: 0;
}
.slippage-tolerance-value .first span.active {
    background-color: var(--primary-color-300);
    color: white;
}

.customValue span {
    width: 100% !important;
    max-width: 100px !important;
}
.customValue .input-pos-wrapper {
    width: 100% !important;
    max-width: 135px !important;
}
.transaction-deadline-input .input-pos-wrapper {
    width: 236px;
}

.btn {
    min-width: 80px;
    min-height: 40px;
}

/* .btn-secondary {
  background: var(--primary-color-300);
  border: 1px solid var(--primary-color-300);
  border-radius: 12px;
} */

.form-control::placeholder {
    color: var(--system-color-600);
}

.form-control {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--system-color-600);
}

.input-pos-wrapper {
    position: relative;
}

.percent-pos {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    color: var(--system-color-600);
}

.form-control:focus {
    box-shadow: none;
}

.transaction-frontrun {
    display: flex;
    align-items: center;
    padding: 10px 13px;
    border: 1px solid var(--system-color-400);
    border-radius: 8px;
    width: 100%;
    margin-top: 8px;
}

.transaction-frontrun h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--secondary-warning-100);
}

.transaction-frontrun img {
    margin-right: 10px;
}

.transaction-deadline {
    margin-top: 18px;
    margin-bottom: 10px;
}

.transaction-deadline-input .form-control {
    background: var(--system-color-100) !important;
    border: 1px solid var(--system-color-100) !important;
    border-radius: 12px !important;
}

.transaction-deadline-input .form-control::placeholder {
    color: var(--system-color-500);
}

.transaction-modal-body {
    padding: 0;
}

.transaction-modal-pad-1 {
    padding: 0 44px;
}

.transaction-modal-content {
    padding: 0;
}

.transaction-modal-header {
    padding: 44px 44px 26px 44px;
}

.transaction-deadline-input {
    margin-bottom: 18px;
}

.interface-settings-wrapper {
    padding: 24px 44px 36px;
    background: var(--system-color-200);
    border-top: 1px solid var(--system-color-400);
}

.interface-title h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--system-color-600);
    margin-bottom: 16px;
    text-align: left;
}

.multiHops-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* toggle switch  */

.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 28px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--system-color-100);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--primary-color-300);
}
input + .slider {
    background-color: var(--system-color-400);
}

/* input:focus+.slider {
    box-shadow: 0 0 1px var(--primary-color-300);
} */

input:checked + .slider:before {
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
}

/* input:checked+.slider:before */

input:checked + .slider .slider-icon:before {
    content: '';
    position: absolute;
    background-image: url(../images/svg/check.svg);
    height: 10px;
    width: 10px;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

input:checked + .slider .slider-icon:after {
    display: none;
}

.slider .slider-icon:after {
    content: '';
    position: absolute;
    background-image: url(../images/svg/minus.svg);
    height: 2px;
    width: 8px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
    background-color: var(--system-color-500);
}

input:checked + .slider.round:before {
    background-color: var(--system-color-600);
}

/* toggle switch  */

input[type='checkbox']:focus {
    outline: none !important;
}

.multiHops-first img {
    margin-left: 9px;
}

.pointer {
    cursor: pointer;
}
.disable_pointer {
    cursor: default !important;
}
.TransactionSettingModal .modal-dialog {
    max-width: 422px;
}
.TransactionSettingModal .modal-body,
.TransactionSettingModal .modal-content {
    padding: 0 !important;
}
.TransactionSettingModal .modal-header {
    padding: 2rem;
}

/* transaction settings modal ends here*/

/* ------------------------------- hover state ends here ------------------------------ */

/* Swap Token Select Token Modal Start*/
.mostRecentSelected {
    border: 1px solid var(--primary-color-300) !important;
    background: #f152235c !important;
    /* pointer-events: none; */
}
.selectSwap {
    color: var(--system-color-600);
}
.tokenNotFound,
.getToken .loader-spine.me-1,
.Explore_Pools,
.voting_block,
.centered_block {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.tokenNotFound p {
    color: var(--system-color-500);
}

.tokenListIcon {
    width: 20px;
    height: 20px;
}
.tokenListIcon.with_Chains {
    width: 25px;
    height: 25px;
}
.cursor-pointer {
    cursor: pointer;
}

.SelectTokenModal {
    max-width: 440px;
}
.SelectSwapOptionModal {
    max-width: 500px !important;
}
.wallet_desc_dropdown .modal-content {
    background: transparent;
    border: none;
}

.priceTag {
    width: 32%;
    display: inline-block;
}
.popular_Tokens {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.popular_Tokens .priceTag {
    width: calc(33.333% - 10px);
}
.check {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
.priceTag:hover .check {
    opacity: 1;
}

.wallet_desc {
    position: relative;
    /* width: 335px; */
    margin: 0 auto;
}
.SelectTokenModal .inside_new_search {
    background-color: var(--system-color-200);
    border-radius: 12px;
    border: 1px solid var(--system-color-400);
}
.inside_new_search form {
    margin-bottom: 20px;
    position: relative;
    /* margin-top: 10px; */
}
.inside_new_search input {
    background-color: var(--system-color-100);
    border: 0;
    padding: 14px 10px 14px 20px;
    font-size: 14px;
    margin-right: 0;
    color: var(--system-color-600);
    border-radius: 12px;
}
.form-control {
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--system-color-600);
}
.inside_information form button {
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
}
.downArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}
.curr_info {
    padding: 0 20px;
    border-bottom: none !important;
    padding-bottom: 15px;
}
.curr_info_token {
    padding: 0 20px;
    border-bottom: 1px solid var(--system-color-400);
    padding-bottom: 15px;
}
.curr_info_token p,
.namedrop .chain_Info p {
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
}
.curr_list .tokenDropDown.active,
.curr_list .tokenDropDown:hover {
    margin-right: 3px;
    margin-bottom: 3px;
    background: var(--primary-color-300);
    transition: 0.3s;
}

.curr_list .tokenDropDown {
    background: transparent;
    border-radius: 12px;
    padding: 7px 0px;
    font-size: 15px;
    display: flex;
    transition: 0.3s;
    height: auto;
    margin: 3px 3px;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
}
.curr_list .tokenDropDown img {
    margin: 0 5px;
    vertical-align: middle;
}
.curr_list .tokenDropDown.active img.check {
    opacity: 1;
    transition: 0.3s;
}

.nav-tabs .nav-link.active {
    background: var(--system-color-300);
    border: 1px solid var(--primary-color-300);
    color: var(--system-color-600);
}
.nav-tabs .nav-link {
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 100px;
    color: var(--system-color-500);
    flex-shrink: 0;
    margin-bottom: 2px;
    margin-right: 10px;
    font-size: 12px;
    padding: 6px 13px;
}
/* .group_inside {
    width: 100%;
    height: 220px;
    overflow: scroll;
    overflow-x: hidden;
}  */
.group_inside p.title {
    font-size: 12px;
    background: var(--system-color-300);
    text-align: center;
    padding: 5px;
    color: var(--system-color-500);
}

.list_div {
    display: flex;
    align-items: center;
    /* margin-bottom: 20px; */
}
.list_div img {
    margin-right: 12px;
    flex-shrink: 1;
}
.list_div p {
    display: flex;
    margin-bottom: 0;
}
.list_div span {
    color: var(--system-color-500);
}
.selectToken {
    /* width: 80%; */
    /* background-color: var(--system-color-300);
  padding: 6px 6px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 105px; */

    padding: 2px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 105px;
    border-radius: 12px;
}

/* button.tokenDropDown.selectToken:focus {
    background-color: var(--system-color-400);
} */
.tokenDropDownSelection {
    display: flex;
    align-items: center;
}
button.tokenDropDown:focus .tokenDropDownSelection {
    padding: 0px 0px !important;
    min-width: 0px !important;
    background-color: var(--system-color-300);
    border-radius: 12px;
}
.tokenDropDownSelection i,
.selectToken i {
    font-size: 10px;
    color: var(--system-color-600);
}
.chainsBlock {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(52px, 1fr));
    grid-auto-rows: 56px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 12px;
    height: 124px !important;
    overflow: scroll !important;
    overflow-x: hidden !important;
}
.walletChainBlock {
    margin-bottom: 10px;
    height: auto !important;
}
.chainsBlock .chain_Info,
.namedrop .chain_Info,
.walletChainBlock .chain_Info {
    background-color: var(--system-color-100);
    border: 1px solid rgb(66, 66, 66);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    padding: 0px;
    box-sizing: border-box;
    transition: background-color 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    display: grid;
    place-items: center;
    min-width: 52px;
    height: 56px;
}
.chainsBlock .chain_Info.active,
.namedrop .chain_Info.active,
.walletChainBlock .chain_Info.active {
    background-color: rgba(63, 73, 225, 0.42) !important;
    border: 1px solid rgb(63, 73, 225) !important;
}
.chainsBlock .chain_Info .chainTag,
.namedrop .chain_Info .chainTag,
.walletChainBlock .chain_Info .chainTag,
#SelectNetworkClose .chainTag {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: 'Inter var', Inter, sans-serif;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
    width: 40px;
    height: 40px;
}
.chainsBlock .chain_Info .chainTag img,
.namedrop .chain_Info .chainTag img,
.walletChainBlock .chain_Info .chainTag img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
}
#SelectNetworkClose .chainTag {
    width: 20px;
    height: 20px;
}
.chainIcon {
    position: absolute;
    top: 50%;
    left: 10%;
}
.flax-auto {
    flex: auto;
}
.chainIcon div {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: 'Inter var', Inter, sans-serif;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
    background: rgb(33, 33, 33);
    width: 16px;
    height: 16px;
    border: 2px solid var(--system-color-100);
}
.chainIcon div img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: contain;
    color: transparent;
    text-indent: 10000px;
}
/* Swap Token Select Token Modal End*/

/* Transaction Confirm  Modal Start*/
.btn-close-pos {
    position: absolute;
    right: 26px;
}
.btn-close {
    opacity: 1;
    background: none;
}
.confirm-transaction-wrapper {
    margin-top: 0;
}
.spinner-block-transaction {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}
.spinner-block-transaction img {
    height: 65px;
    /* height: 88px; */
}
.spinner_block img {
    /* margin-right: 20px; */
    -webkit-animation: spCircRot 3s infinite linear;
    animation: spCircRot 3s infinite linear;
}
.confirm-transaction-desc {
    text-align: center;
}
.confirm-transaction-desc h4 {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--system-color-600);
    margin-bottom: 8px;
}
.confirm-transaction-desc h5 {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--system-color-600);
    margin-bottom: 16px;
}
.confirm-transaction-desc h6 {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--system-color-500);
    margin-bottom: 0;
}
.confirm-transaction-link {
    margin-bottom: 16px;
}
.ConfirmTransactionModal {
    background-color: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    text-align: center;
    overflow: hidden;
}
.transactionDenied {
    width: 10%;
}
/* Transaction Confirm  Modal End*/

/* Insufficient ETH Balance Start*/
.InsufficientBalance {
    background-color: transparent;
    border: 1px solid var(--system-color-500);
    color: #b9bacb;
    min-width: 80px;
    min-height: 40px;
    border-radius: 12px;
    display: block;
    padding: 11px 26px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 700;
    text-align: center;
    transition: 0.3s;
}
.InsufficientBalance:hover {
    box-shadow: none !important;
    outline: none !important;
    border-color: var(--primary-color-300) !important;
    background-color: transparent;
    color: #b9bacb;
    transition: 0.3s;
}
/* Insufficient ETH Balance End*/

/* Transaction Price Updated Start*/

.transaction-price-updated {
    margin-bottom: 16px;
    background: var(--secondary-warning-100);
    display: flex;
    align-items: center;
    padding: 10px 13px;
    border: 1px solid var(--system-color-400);
    border-radius: 8px;
    width: 100%;
    margin-top: 8px;
}
.transaction-price-updated img {
    margin-right: 10px;
}
.transaction-price-updated h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--system-color-100);
    font-family: 'Plus Jakarta Sans', sans-serif;
}
/* Transaction Price Updated End*/

/*** Liquidity Pool Star ***/
.main_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main_title .btn.btn-dark {
    background-color: var(--primary-color-300);
    transition: 0.3s;
    display: flex;
    align-items: center;
    width: 164px;
    padding: 7px 20px;
    justify-content: space-around;
}
.table > :not(:last-child) > :last-child > * {
    border-bottom: 1px solid var(--system-color-400);
}
table.dataTable > thead > tr th:first-child {
    padding-left: 30px;
}
table.dataTable > tbody > tr {
    transition: 0.3s;
}
table.dataTable > tbody > tr td:first-child {
    padding-left: 30px;
}
table.dataTable td {
    border: none;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}
.img_comb {
    display: flex;
    align-items: center;
    margin-right: 10px;
    width: fit-content;
    position: relative;
}
.data_tooltip {
    width: auto;
    position: absolute;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    padding: 12px;
    left: 100%;
    background-color: var(--system-color-200);
    top: 0;
    margin-left: 9px;
    width: 164px;
    visibility: hidden;
    z-index: 1;
    opacity: 0;
}
.img_comb:hover .data_tooltip,
.info_menu:hover .data_tooltip {
    visibility: visible !important;
    opacity: 1 !important;
}
.tip_value {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.price_img {
    display: flex;
    align-items: center;
}
.data_tooltip img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}
.tip_value p {
    font-size: 12px;
}
.tip_value span {
    font-size: 12px;
    color: #6c757d;
}
.info_menu {
    position: relative;
    width: fit-content;
}
.info_menu .data_tooltip {
    width: 240px;
}
.action_btns {
    display: flex;
    align-items: center;
}
.btn-black.btn {
    min-width: 88px;
    padding: 4px;
    color: var(--system-color-600);
    font-size: 12px;
    background-color: var(--system-color-100);
    min-height: auto;
    border-radius: 8px;
    font-weight: 500;
}
.action_btns span {
    margin: 0 6px;
}
table.dataTable > tbody > tr:hover {
    background-color: var(--primary-color-300);
}
.responsiveTable td .tdBefore .hiddenOnMobile {
    display: none;
    visibility: hidden;
}
.action_btns span:hover,
.action_btns a:focus span {
    background-color: var(--primary-color-300);
    transition: 0.5s;
}
table.dataTable > tbody > tr:hover {
    background: var(--system-color-200);
    border-radius: 8px;
    overflow: hidden;
}
.scanLPTokens {
    background-color: var(--primary-color-300);
    transition: 0.3s;
    display: flex;
    align-items: center;
    width: 180px;
    padding: 7px 20px;
    justify-content: space-around;
    border: none;
    color: #fff;
}
.scanLPTokensHover:hover {
    transition: 0.3s;
    background-color: transparent;
    border: 1px solid var(--primary-color-300);
    color: var(--system-color-600) !important;
}

.scanLPTokenButton {
    padding-left: 48%;
}
/*** Liquidity Pool End ***/

/*** Liquidity Create Pool Start ***/
/*Liquidity Create Pool STEP 1 Native assets Start*/
.steps_follow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    max-width: 832px;
}
.step_block.active {
    border: 1px solid var(--primary-color-300);
}
.step_block {
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    padding: 10px 8px 10px 16px;
    width: fit-content;
    display: flex;
    align-items: center;
    width: 188px;
    position: relative;
}
.step_block.active .step_img {
    background: var(--primary-color-300);
}
.step_img {
    width: 32px;
    height: 32px;
    background: var(--system-color-400);
    line-height: 29px;
    text-align: center;
    border-radius: 100px;
    margin-right: 17px;
}
.step_block.active .step_img img {
    filter: brightness(0) invert(1);
}
.step_block .step_img .check {
    margin: 11px auto;
}
.step_block.success .step_img {
    background: var(--secondary-success-100);
}
.step_block.success .step_number p {
    color: var(--system-color-600);
}
.step_number span {
    font-weight: 400;
    font-size: 10px;
    color: var(--system-color-500);
}
.step_block.active p {
    color: var(--system-color-600);
}
.step_number p {
    color: var(--system-color-500);
}
.step_block::after {
    content: '';
    border: 1px dashed var(--system-color-400);
    display: block;
    position: absolute;
    left: 100%;
    width: 140px;
    z-index: -1;
}
.step_information {
    margin-top: 40px;
}
.step_information .token_block.active {
    display: block;
}
.step_information .token_block {
    display: none;
}
.token_block {
    background-color: var(--system-color-300);
    /* padding: 44px; */
    padding: 34px 44px 34px 44px;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    max-width: 384px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.step_data .flex_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.flex_title h1,
.modalCoins,
.PoolReservesTitle h1 {
    font-size: 16px;
    font-weight: 500;
}
.step_data .wallet_desc {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.step_data .wallet_desc .wallet_info {
    background: var(--system-color-100);
    border-radius: 12px;
    padding: 10px 8px 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
/* .wallet_value {
  max-width: 50%;
  width: 100%;
} */
.wallet_value h2 {
    font-size: 22px;
    line-height: normal;
    margin-bottom: 2px;
}
.wallet_value_price {
    font-weight: 700;
    text-align: end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tokenPrice {
    text-align: end;
    width: 100%;
    font-size: 20px;
    background-color: transparent;
    border: 0;
    font-weight: 630;
    margin-right: 0;
    color: var(--system-color-600);
    border-radius: 12px;
    padding: 0px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ad_tokenPrice {
    text-align: start;
    width: 100%;
    font-size: 14px;
    background-color: transparent;
    border: 0;
    font-weight: 400;
    margin-right: 0;
    color: var(--system-color-600);
    padding: 0px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tokenPrice_CP {
    width: 100%;
    font-size: 20px;
    background-color: transparent;
    border: 0;
    font-weight: 630;
    margin-right: 0;
    color: var(--system-color-600);
    border-radius: 12px;
    padding: 0px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.wallet_value p {
    color: var(--system-color-500);
    font-size: 12px;
}
.wallet_dropdown .dMenu1 {
    position: relative;
}
.select_popup {
    position: relative;
    padding: 0 30px;
    margin: 0 12px;
    font-size: 16px;
}
.select_popup::after {
    content: '';
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    margin: 0;
    width: 30px;
    background-position: center;
    height: 30px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    right: -2px;
    font-size: 20px;
    line-height: normal;
}
.stepBlock_btn.more_margin {
    margin-top: 30px;
}
.stepBlock_btn,
.DropDownProfile {
    display: flex;
    align-items: center;
    justify-content: center;
}
.stepBlock_btn .btn:first-child {
    margin-right: 28px;
}
.stepBlock_btn .btn {
    width: 100%;
}
.btn-primary {
    color: var(--system-color-600);
    background-color: transparent;
}
.btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:active {
    background: var(--primary-color-300);
    border: 1px solid var(--primary-color-300);
    border-radius: 12px;
    box-shadow: none !important;
}
.btn-primary:hover {
    border: 1px solid var(--primary-color-300) !important;
}
.additionalInfo_Block {
    height: 100%;
    overflow: hidden;
    max-width: 250px !important;
}
.additionPart_Block {
    max-height: 212px;
    overflow: auto !important;
    max-width: 250px !important;
    margin-top: 15px;
}
.add_dialogue {
    position: absolute;
    top: 0;
    left: 100%;
    max-width: 188px;
    width: 100%;
    margin-left: 37px;
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
}
.PoolReservesAdditionalBlock {
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
}
.additionalInfo_Block a {
    border-top: none !important;
    border-bottom: 1px solid var(--system-color-400) !important;
    padding: 12px 0px !important;
}
.add_info {
    padding: 16px 16px 12px 16px;
}
.add_dialogue p,
.PoolReservesAdditionalBlock p {
    margin-bottom: 8px;
}
.add_dialogue span,
.PoolReservesAdditionalBlock span {
    font-size: 12px;
    display: block;
    line-height: 20px;
    font-weight: 400;
}
.add_dialogue a,
.PoolReservesAdditionalBlock a {
    font-size: 12px;
    color: var(--primary-color-300);
    display: flex;
    align-items: center;
    border-top: 1px solid var(--system-color-400);
    padding: 12px 20px;
    font-weight: 500;
}
.add_dialogue a img,
.PoolReservesAdditionalBlock a img {
    margin-left: 10px;
}
.block7 .agg_text p {
    margin-bottom: 12px;
}
.agg_text p {
    font-size: 12px;
    margin-bottom: 20px;
    line-height: 20px;
    font-weight: 400;
}
.copy_data {
    width: 65%;
    justify-content: end !important;
}

.copy_info,
.copy_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copy_info span {
    color: var(--system-color-500);
}
.copy_info p,
.copy_info span {
    font-size: 12px;
}
.copy_data a .copy {
    margin: 0 8px;
}
.inside_new_search.inside_information {
    background-color: transparent;
}
.inside_new_search {
    background-color: var(--system-color-200);
    border-radius: 12px;
}
.searchbar {
    padding: 0 40px 0 40px;
}
.searchbar form {
    border-bottom: 0;
    margin-bottom: 8px;
}
.inside_information form {
    position: relative;
}
.searchbar input {
    border-radius: 12px;
    border-bottom: 0;
}
.inside_new_search input {
    padding-right: 40px;
}
.inside_information input {
    background-color: var(--system-color-100);
    border: 0;
    padding: 14px 10px 14px 10px;
    font-size: 14px;
    margin-right: 0;
    color: var(--system-color-600);
    border-radius: 12px !important;
}
.inside_information form button {
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
}
.select_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--system-color-400);
    padding-bottom: 12px;
    padding-left: 44px;
    padding-right: 44px;
}
.select_search p,
.select_search span,
.pool_data span {
    font-size: 12px;
}
.select_search span,
.pool_data span {
    color: var(--system-color-500);
}
.block4 .curr_information.list_below {
    padding: 0;
}
.curr_information.list_below {
    border-bottom: 0;
}
.block4 .all_tabs {
    margin-bottom: 38px;
}
.block4 .curr_information.list_below .nav.nav-tabs {
    justify-content: flex-start;
}
.curr_information.list_below .nav.nav-tabs {
    padding: 12px 0 12px 20px;
}
.block4 p.title {
    background-color: var(--system-color-100);
}
.tab-pane p.title {
    font-size: 12px;
    /* background: var(--system-color-300); */
    text-align: center !important;
    padding: 1px;
    color: var(--system-color-500);
}
.list_types {
    padding: 0 20px;
}
.form-group.checklist input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
.inside_new_search input {
    padding-right: 0px;
}
.form-group.checklist label {
    position: relative;
    cursor: pointer;
    font-size: 12px;
}
.pool_details {
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    padding: 16px;
    margin-top: 24px;
}
.pool_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.more_mg {
    margin-top: 69px;
}
.stepBlock_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}
.nativeSelect {
    border-radius: 6px;
    padding: 7px;
    background-color: #a7b9d3;
    z-index: 99;
    width: 110px;
}

.poolFeeDropDown {
    border-radius: 6px;
    padding: 7px;
    background-color: #a7b9d3;
    z-index: 99;
    width: 70px;
    position: absolute;
    top: 100%;
    left: 42px;
}

.nativeSelectFirst {
    position: absolute;
    top: 100%;
    right: 0px;
    left: auto;
}
.nativeSelectSecond {
    position: absolute;
    top: 285%;
    right: 0px;
    left: auto;
}

.nativeSelect ul li,
.poolFeeDropDown ul li {
    padding: 6px;
    border-radius: 6px;
}
.nativeSelect ul li:hover,
.poolFeeDropDown ul li:hover {
    background-color: #000;
    color: var(--system-color-600) !important;
    cursor: pointer;
}

.nativeSelect ul .selected,
.poolFeeDropDown ul .selected {
    background-color: var(--system-color-600);
    cursor: pointer;
}
.nativeSelect ul .selected span,
.poolFeeDropDown ul .selected span {
    color: #000;
}
.nativeSelect ul li img {
    margin-right: 10px;
}
.nativeSelect ul li span,
.poolFeeDropDown ul li span {
    vertical-align: middle;
}
.poolFeeDropDown ul li span {
    font-size: 15px;
    color: var(--system-color-600);
}
.selectAssets {
    margin-left: 15px;
}
.wallet_dropdown p.red_text {
    color: var(--primary-color-300);
    position: absolute;
    bottom: 2px;
    right: 100%;
    margin-right: 10px;
}
.wallet_dropdown p {
    text-align: right;
    font-size: 12px;
    padding-top: 2px;
}

.fee_dropdown {
    position: relative;
}
.fee_dropdown span,
.total_pay span,
.add_lq span {
    font-size: 12px;
    color: var(--system-color-500);
    font-weight: 500;
    padding-top: 3px;
    vertical-align: middle;
}
.dMenu1 {
    position: relative;
    /* width: 100%; */
    max-width: 220px;
}
.total_pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    background: var(--system-color-300);
    padding: 12px;
    /* margin-bottom: 25px; */
}
.total_pay p,
.pool_data .pool_data_details {
    color: var(--system-color-600);
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
    text-align: end;
}
.total_pay .successTxHash span {
    color: var(--system-color-600);
    font-weight: 600;
}
.selectPoolFee b::after {
    content: '\f107';
    font-family: FontAwesome;
    position: absolute;
    top: 245px;
    margin: 0;
    height: auto;
    transform: translateY(-50%);
    right: 60%;
    font-size: 15px;
    line-height: normal;
    color: #ffffffbd;
}
.fee_dropdown .selectAssets {
    color: var(--system-color-600);
}
.red_text_step {
    color: #f12323 !important;
    font-size: 13px;
}

.AddLiquidity {
    max-width: 835px !important;
    padding: 38px !important;
}
.addLiquidity_approve {
    padding: 11px 3px !important;
    font-size: 14px;
}
.Head_desc {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}
.Set_Price_Range {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--secondary-danger-100);
}

.Current_Token_Price {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Current_Token_Price span {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: var(--system-color-500);
}
.Current_Token_Price input {
    background-color: var(--system-color-100);
    border-radius: 5px;
    border: none;
    text-align: right;
    color: white;
    font-size: 14px;
    opacity: 0.6;
}
.Current_Token_Price input:focus {
    opacity: 1;
}
.min_max_price {
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
}
.min_p {
    color: #98a1c0;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}
.min_max_price i {
    background: var(--system-color-100) !important;
    border-radius: 8px !important;
    padding: 8px;
    cursor: pointer;
}
.In_range {
    background: var(--system-color-400);
    border-radius: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
}
.In_range span {
    color: var(--system-color-500);
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}
.In_range i {
    background: var(--secondary-success-100) !important;
    width: 7px;
    height: 7px;
    border-radius: 25px;
}
.pairsTokens {
    background: var(--system-color-400);
    border-radius: 16px;
    padding: 20px;
}
.Selected_Range {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--system-color-500);
}
.SelectRange .pairsTokens {
    padding: 12px !important  ;
}

.disabled_block {
    pointer-events: none;
    opacity: 1.65;
    filter: blur(1px);
}
.default_disabled_block {
    opacity: 0.65;
    filter: blur(2px);
}
.ALposition {
    padding: 40px 30px;
}
.ALposition img {
    width: 17%;
}
.ALposition p {
    color: var(--system-color-400);
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
}

.setBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
}

/*SWITCH 3 ------------------------------------------------*/

.tokenSelector {
}

/*  Toggle Switch  */

.tokenSelector .toggleSwitch span span {
    display: none;
}

.tokenSelector .toggleSwitch {
    display: inline-block;
    position: relative;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    width: 125px;
    background-color: var(--system-color-200);
    border: 1px solid var(--system-color-400);
    border-radius: 8px;
    height: 34px;
}
.tokenSelector .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.tokenSelector .toggleSwitch label,
.tokenSelector .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
}
.tokenSelector .toggleSwitch input:focus ~ a,
.tokenSelector .toggleSwitch input:focus + label {
    outline: none;
}
.tokenSelector .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
}
.tokenSelector .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
}
.tokenSelector .toggleSwitch > span {
    position: absolute;
    left: 0;
    width: calc(100% - 6px);
    margin: 0;
    text-align: left;
    white-space: nowrap;
    margin: 0 3px;
}
.tokenSelector .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: auto;
    left: 0;
    top: -1px;
    opacity: 1;
    width: 40%;
    text-align: center;
    line-height: 34px;
}
.tokenSelector .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4 !important;
    display: block;
    top: 3px;
    bottom: 3px;
    padding: 5px 10px 5px 10px;
    left: 3px;
    width: 50% !important;
    background-color: var(--system-color-100);
    border-radius: 6px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.tokenSelector .toggleSwitch > span span:first-of-type {
    color: var(--system-color-600);
    opacity: 1;
    left: 0;
    margin: 0;
    width: 50%;
    font-weight: 500;
}
.tokenSelector .toggleSwitch > span span:last-of-type {
    left: auto;
    right: 0;
    color: var(--system-color-400);
    margin: 0;
    width: 50%;
    font-weight: 500;
}
.tokenSelector .toggleSwitch > span:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -2px;
    /* background-color: #fafafa;
	border: 1px solid #ccc; */
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.tokenSelector .toggleSwitch input:checked ~ a {
    left: calc(50% - 3px);
}
.tokenSelector .toggleSwitch input:checked ~ span:before {
    /* border-color: #0097D1;
	box-shadow: inset 0 0 0 30px #0097D1; */
}
.tokenSelector .toggleSwitch input:checked ~ span span:first-of-type {
    left: 0;
    color: var(--system-color-400);
}
.tokenSelector .toggleSwitch input:checked ~ span span:last-of-type {
    /* opacity: 1;
	color: var(--system-color-600);	 */
    color: var(--system-color-600);
}
/* Switch Sizes */
.tokenSelector .toggleSwitch.large {
    width: 60px;
    height: 27px;
}
.tokenSelector .toggleSwitch.large a {
    width: 27px;
}
.tokenSelector .toggleSwitch.large > span {
    height: 29px;
    line-height: 28px;
}
.tokenSelector .toggleSwitch.large input:checked ~ a {
    left: 41px;
}
.tokenSelector .toggleSwitch.large > span span {
    font-size: 1.1em;
}
.tokenSelector .toggleSwitch.large > span span:first-of-type {
    left: 50%;
}
.tokenSelector .toggleSwitch.xlarge {
    width: 80px;
    height: 36px;
}
.tokenSelector .toggleSwitch.xlarge a,
.gov_col .img_comb img {
    width: 36px !important;
}
.tokenSelector .toggleSwitch.xlarge > span {
    height: 38px;
    line-height: 37px;
}
.tokenSelector .toggleSwitch.xlarge input:checked ~ a {
    left: 52px;
}
.tokenSelector .toggleSwitch.xlarge > span span {
    font-size: 1.4em;
}
.tokenSelector .toggleSwitch.xlarge > span span:first-of-type {
    left: 50%;
}
.addLPToggle {
    position: relative;
    top: 2px;
}
/*  End Toggle Switch  */

/*SWITCH 3 ------------------------------------------------*/
/*Liquidity Create Pool STEP 1 Native assets End*/

/*Liquidity Create Pool STEP 2 Whitelist tokens Start*/
.token_block.block4 {
    padding: 0;
}
.token_block.block4 .flex_title {
    padding: 40px 40px 0 40px;
}
.searchbar input:focus {
    background-color: var(--system-color-100);
}
.form-group.checklist label:before {
    content: '';
    -webkit-appearance: none;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
        inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 0;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    width: 16px;
    height: 16px;
    border: 1px solid #80819171;
    border-radius: 4px;
    background-color: var(--system-color-100);
}
.form-group.checklist input:checked + label:after {
    content: url(../images/svg/check.svg);
    display: block;
    position: absolute;
    top: 2px;
    left: 3px;
    width: 5px;
    height: 10px;
    border-width: 0 2px 2px 0;
    transform: scale(0.8);
}
.form-group.checklist input:checked + label:before {
    background-color: var(--primary-color-300);
}
.block4 .stepBlock_btn {
    padding: 0 36px 36px 36px;
}
.stepBlock_btn.more_margin a,
.investCancel {
    margin-right: 15px;
}
.investCancel .btn-primary {
    margin-right: 30px !important;
}
.investCancel span,
.stepBlock_btn .btn-primary {
    color: var(--system-color-600);
}
/*Liquidity Create Pool STEP 2 Whitelist tokens End*/

/*Liquidity Create Pool STEP 3 Confirmation start*/

.pool_data p,
.Price_impact_warning span,
.ld_pool_details .reward {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    font-size: 12px;
}
.block6 .stepBlock_btn {
    margin-top: 16px;
}
.block7 .stepBlock_btn {
    margin-top: 24px;
}
.successTxHash {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.CopyToClipboard {
    background: transparent;
    border: none;
    margin-right: 10px;
}
.Select_Pair_desc {
    padding-top: 14px;
}
.Pool_details_desc {
    margin-top: 10px;
}
.details_desc_tokens {
    padding-top: 16px;
}
.plus_swap i {
    color: #7780a0;
}
.tokenDropDown span.custom_btn.pointer.add_lqd {
    background: #808191 !important;
    border: none !important;
}
.add_lq {
    background: var(--system-color-200) !important;
    border: 1px solid var(--system-color-400) !important;
    border-radius: 12px;
    padding: 5px 12px;
}
.add_lq p {
    text-align: center;
}
.add_lq span,
.stakingEpoch {
    font-size: 10px;
}
.add_lq_confirm {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: var(--system-color-600);
}
.success_tip {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: var(--system-color-600);
}
.pool_share_block {
    border-top: 1px solid var(--system-color-400);
    border-radius: 12px;
}
.pool_share_block p .poolBalance {
    font-size: 12px;
    font-weight: 600;
    color: var(--system-color-600);
}
.pool_share_block p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.PoolReservesBlock {
    padding: 34px 20px 34px 20px;
    /* height: 0%; */

    background-color: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    margin: 0;
    position: relative;
}
.AddLiquidity_block {
    margin-right: 0px;
}
.PoolReservesTitle {
    margin-bottom: 8px;
}
.PoolReservesBlock table .text-white {
    color: var(--system-color-600) !important;
}
.PoolReservesBlock .invest_details_curr {
    padding: 12px;
}
.PoolReservesBlock .img_comb {
    margin-right: 5px;
}
.PoolReservesBlock .pool_data {
    margin-top: 13px;
    margin-bottom: 0px;
}
.PoolReservesBlock .img_comb img {
    height: 18px;
    width: 18px;
}
.plReserve_Block {
    max-height: 334px;
    overflow: auto;
}
.pl_block_blur_area::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 368px;
    left: 21px;
    pointer-events: none;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        var(--system-color-100) 90%
    );
    width: 90%;
    height: 3em;
    border-radius: 12px;
}
.pool_data .youSell {
    font-size: 12px !important;
}
.reserve_pool_title th {
    border-bottom: none !important;
}

.rp_line {
    border-top: 1px solid #808191;
    flex-grow: 1;
    margin: 0 10px;
}
.plReserve_info .successTxHash {
    max-width: 50%;
}
.pl_block_blur_area table tbody tr td {
    vertical-align: middle;
}
.reserve_pool_title {
    padding-left: 10px;
    padding-right: 10px;
}
.reserve_pool_title th {
    vertical-align: middle;
}
/*Liquidity Create Pool STEP 3 Confirmation End*/

/*** Liquidity Create Pool End ***/

/*** Liquidity Invest Pool Start ***/
/*Liquidity Invest Pool STEP 1 Amount Start*/

.plus_swap {
    display: block;
    text-align: center;
    margin: 8px 0;
}
.total_pay.invest {
    margin-top: 20px;
}
.block2 .transaction-frontrun {
    border: 0;
    padding: 0;
}
.second_slippage_block {
    margin-top: 24px;
}
.type_list {
    margin-bottom: 74px;
}
.single_type {
    background: var(--system-color-300);
    border: 1px solid var(--system-color-500);
    border-radius: 12px;
    width: 88px;
    display: inline-block;
    padding: 9px 10px;
    text-align: center;
    font-size: 14px;
    margin-right: 12px;
}
.investing_modal img {
    height: 10px !important;
}
.pool_data:last-child {
    margin-bottom: 0 !important;
}
.pool_details.invest_details {
    margin-top: 16px;
    margin-bottom: 10px;
    padding: 12px 16px;
}
.pool_details.invest_details .pool_data {
    margin-bottom: 8px;
}
.invest_details_curr .pool_data p,
.invest_details_curr .pool_data span {
    font-size: 14px;
}
.red_para,
.red_para a,
.red_para:hover {
    color: var(--primary-color-300);
    font-size: 12px;
    display: block;
    margin-bottom: 60px;
}
.confirm_invest {
    width: 100% !important;
}
.step3::after {
    content: '';
    border: none !important;
    display: block;
    position: absolute;
    left: 100%;
    width: 140px;
    z-index: -1;
}
.InvestTokenPreview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40%;
}
/*Liquidity Invest Pool STEP 1 Amount End*/
/*** Liquidity Invest Pool End ***/

/* Select network dropdown style changes css start*/
.select-network-drop {
    display: none;
    width: 150px;
    max-width: 350px;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    position: absolute;
    top: 115%;
    left: -81px;
    right: 0;
    background: var(--system-color-300);
    padding: 8px;
    z-index: 1;
}
/* Select network dropdown style changes css end*/

/*** Liquidity Withdraw Pool Start ***/
/*Liquidity Withdraw Pool STEP 1 Withdraw Start*/
.withdraw_area .wallet_information {
    padding: 12px 16px !important;
}
.withdraw_area .coin_name {
    justify-content: flex-end;
}
.coin_name {
    display: flex;
    align-items: center;
}
.withdraw_area .coin_name p {
    font-size: 16px;
    color: var(--system-color-600) !important;
}
.coin_name p {
    transition: 0.3s;
}
.wallet_dropdown p {
    text-align: right;
    font-size: 12px;
    color: var(--system-color-500);
    padding-top: 2px;
}
.wallet_information {
    background: var(--system-color-100);
    border-radius: 12px;
    padding: 10px 8px 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    min-height: 80px;
}
.stepBlock_btn .value4 {
    margin-right: 0px !important;
}
.fa-sort-down_position {
    margin: 0px 0px 0px -0.54rem;
}

.sort_success {
    color: #7676768c;
}

.goBackWithdraw {
    font-size: 25px;
}

.Withdraw_preview {
    display: flex;
    justify-content: flex-start !important;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--system-color-400);
}
.Withdraw_preview h1 {
    font-weight: 600;
}
.Withdraw_preview p {
    color: var(--system-color-500);
    padding-top: 10px;
}
.isWithdraw_success {
    padding: 0px !important;
}
.Withdraw_Detailed {
    color: var(--primary-color-300);
    font-weight: 600;
}

.RangeValue {
    font-size: 30px;
    font-weight: bolder;
}
.rangeOption {
    background: var(--system-color-100);
    border-radius: 20px;
    padding: 6px 19px;
}
.rangeOption button {
    color: var(--primary-color-300);
    font-weight: 600;
}
.confirm_UnStake .slideOptionSelection {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}
.confirm_UnStake .rangeOption {
    background: var(--system-color-100);
    border-radius: 20px;
    padding: 6px 19px;
    margin-bottom: 0;
}
.receivedTokens {
    font-size: 12px !important;
    font-weight: bold !important;
}

.range_down_arrow {
    font-size: 20px !important;
}

.withdraw_curr {
    background: var(--system-color-100) !important;
}
.Remove_Liquidity,
.Remove_Liquidity span {
    font-size: 16px;
    font-weight: 600;
}
.Remove_Liquidity .withdraw_Desc {
    display: flex;
    justify-content: flex-start;
}
.Remove_Liquidity .withdraw_Desc p {
    font-size: 13px;
    font-style: italic;
}
.WithdrawConfirmation .modal-dialog {
    padding: 27px !important;
}
/*Liquidity Withdraw Pool STEP 1 Withdraw End*/
/*** Liquidity Withdraw Pool End ***/

/*** Pool Info Start ***/
table.dataTable td,
table.dataTable th {
    color: var(--system-color-600) !important;
    vertical-align: middle;
    padding: 14px 10px;
}
.table > :not(:last-child) > :last-child > * {
    border-bottom: 1px solid var(--system-color-400);
}
table.dataTable thead > tr > th.sorting:before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    content: '\f063';
    font-family: FontAwesome;
}
.assetsTitle {
    color: var(--system-color-600);
    cursor: pointer !important;
}
table.dataTable > tbody > tr:hover .assetsTitle {
    color: var(--primary-color-300) !important;
    transition: 0.3s;
}
.info_Reserves {
    padding: 14px 0px;
}
.info_pools_listing {
    max-width: 86% !important;
}
.pool_APR span {
    color: #ff521c !important;
    font-size: 14px !important;
}
.poolRewardToolTip table.bootToolTip thead th {
    text-align: center !important;
    padding: 8px;
}
.Featured_Numbers .border-wrap {
    background: linear-gradient(to bottom, #ff5701, var(--system-color-300) 80%);
    padding: 1px 13px 0;
    border-radius: 18px;
    box-shadow: 0px 20px 10px 0px rgba(0, 0, 0, 0.15);
}
.Featured_Numbers .return_on_Featured {
    background: linear-gradient(180deg, var(--system-color-200) 0%, var(--system-color-300) 100%);
    box-shadow: 0px 25px 24px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    padding: 20px 40px;
}
.Featured_Numbers .return_on_Featured .col {
    text-align: center;
    color: var(--system-color-600);
}
.Featured_Numbers .featuredValue {
    font-family: 'Plus Jakarta Sans';
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    color: #ff5701;
    text-shadow: 0px 0 9px #ff56009e;
    margin-bottom: 18px !important;
}

.Featured_Numbers .return_on_Featured {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
}
.Featured_Numbers .return_on_Featured .col {
    flex: 1;
    text-align: center;
    color: var(--system-color-600);
    margin: 0 10px;
    margin-bottom: 20px;
}
/*** Pool Info End ***/

/*** Info Pool Detail Start ***/
.info_dashboard .inner_area {
    max-width: 100%;
}
.pool_header {
    background: var(--system-color-300);
    border-radius: 12px;
    padding: 20px 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.pool_header .pool_menus {
    flex-shrink: 1;
    display: flex;
    align-items: center;
}
.back_to_pool {
    width: fit-content;
    padding-right: 20px;
    border-right: 2px solid var(--system-color-400);
    padding-top: 10px;
    padding-bottom: 10px;
}
.back_to_pool a {
    display: flex;
    align-items: center;
}
.back_to_pool img {
    transform: rotate(180deg);
    margin-right: 18px;
    width: 8px;
}
.back_to_pool p {
    font-weight: 700;
}
.pool_header .coin_name.first_div {
    padding-left: 24px;
    margin-right: 56px;
    position: relative;
}
.coin_name.first_div .img_comb img {
    width: 32px;
    height: 32px;
}
.pool_header .coin_name.first_div p {
    font-size: 24px;
    line-height: 30px;
}
.pool_header .coin_name.first_div span {
    font-size: 12px;
    color: var(--system-color-500);
}
.copy_icon {
    display: flex;
    align-items: center;
    margin-top: -17px;
    margin-left: 10px;
}
.pool_header .copy_icon a,
.pool_header .copy_icon span {
    width: 24px;
    height: 24px;
    background: var(--system-color-400);
    border-radius: 8px;
    text-align: center;
    margin-left: 5px;
    display: block;
}
.pool_header .copy_icon span img {
    position: relative;
    top: 10%;
}
.dropdownArrow {
    transition: 0.3s;
    transform: rotate(0deg);
}
.curr_dropdown_menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: var(--system-color-300);
    mix-blend-mode: normal;
    box-shadow: 4px 4px 30px rgb(0 0 0 / 40%);
    border-radius: 12px;
    padding: 12px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}
.curr_dropdown_menu ul li span {
    display: flex;
    align-items: center;
    padding: 10px 13px;
}
.curr_dropdown_menu ul li span p {
    width: 16px;
    font-size: 14px !important;
}
.curr_dropdown_menu .coin_name {
    margin-left: 6px;
}
.coin_name.first_div .curr_dropdown_menu .img_comb img {
    width: 20px;
    height: 20px;
}
.curr_dropdown_menu ul li span.active .coin_name p {
    color: var(--primary-color-300);
}
.pool_header .pool_details {
    margin-right: 12px !important;
    padding: 5px 8px;
    width: auto;
}
.pool_header .img_comb img {
    width: 16px;
    height: 16px;
}
.pool_header .pool_data .coin_name p {
    font-size: 14px;
}
.pool_header .pool_menus .poolInfoHeadShare {
    display: inline-block !important;
    width: 32px;
    height: 32px;
    background: var(--system-color-400);
    border-radius: 8px;
    text-align: center;
    line-height: 28px;
}
.pool_header a.share_btn,
.pool_header .pool_menus .css-bbq5bh {
    width: 32px;
    height: 32px;
    background: var(--system-color-400);
    border-radius: 8px;
    text-align: center;
    line-height: 28px;
}
.pool_header a.share_btn img {
    filter: brightness(0) invert(1);
}
.share_btn_sh {
    filter: brightness(0%) !important;
}
.pool_menu_btn a {
    padding: 7px 20px;
    width: 172px;
    text-align: left;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--system-color-600);
}
.pool_menu_btn img {
    margin-right: 20px;
}
.pool_curr_area {
    margin-bottom: 16px;
}
.pool_content_blocks {
    background: var(--system-color-300);
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 20px;
}
.pool_currency_area {
    background-color: var(--system-color-300);
    padding: 16px 20px;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    max-width: 175px;
    width: 100%;
    display: inline-block;
    margin-right: 10px;
}
.pool_currency_area span {
    color: var(--system-color-500);
    font-size: 12px;
}
.pool_currency_area h1 span {
    font-size: 24px;
    font-weight: 600;
    color: var(--system-color-600);
}
.pool_currency_area h1 {
    padding: 4px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 105%;
}
.pool_currency_area p,
.activated_desc {
    color: var(--secondary-success-100);
}
.pool_currency_area p.unActive {
    color: var(--primary-color-300);
}
.graph_tab_area {
    background: var(--system-color-300);
    border-radius: 12px;
    padding: 16px;
}
.graph_title h2 {
    font-size: 16px;
    line-height: normal;
}
.tab_area .nav-tabs {
    background: var(--system-color-100);
    border-radius: 8px;
    padding: 4px;
}
.tab_area .nav-tabs .nav-link.active {
    background-color: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    color: var(--system-color-600);
}
.tab_area .nav-tabs .nav-link {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 14px;
}
.nav-tabs .nav-link:last-child {
    margin-right: 0;
}
span.date {
    display: block;
    color: var(--system-color-500);
    font-size: 14px;
    padding: 6px 0 8px 0;
}
.graph_img img {
    width: 100%;
}
.second_tab.tab_area .nav-tabs .nav-link.active {
    background: transparent;
    border: 1px solid var(--primary-color-300);
    color: var(--primary-color-300);
}
.second_tab.tab_area .nav-tabs .nav-link {
    font-size: 14px;
}
.data_table_list {
    margin-top: 16px;
}
p.red_text,
span.red_text {
    color: var(--primary-color-300);
}
.pool_balance_table {
    background: var(--system-color-300);
    border-radius: 12px;
    padding: 20px;
}
.p_bal_title span {
    font-size: 12px;
    color: var(--system-color-500);
}
.p_bal_title span img {
    margin-left: 8px;
    vertical-align: baseline;
}
.pool_inside_area {
    margin-top: 16px;
}
.token_inside_curr {
    margin-top: 12px;
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    padding: 16px;
    padding-bottom: 0px;
}
.token_curr_data {
    margin-bottom: 12px;
}
.token_curr_data p,
.token_curr_data p span {
    font-size: 16px;
    /* display: flex; */
    align-items: center;
    color: var(--system-color-600);
}
.currency_data img {
    margin-right: 8px;
    width: 14px;
    height: 14px;
}
.token_curr_data span {
    font-size: 12px;
    color: var(--system-color-500);
}
.p_bal_title h2 {
    font-size: 24px;
    line-height: normal;
}
.graph_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.checkGraph {
    flex-wrap: wrap;
    gap: 8px;
}
.curr_dropdown_menu.open {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
}
.curr_dropdown_menu ul li span:hover {
    background: var(--system-color-200);
    border-radius: 8px;
    cursor: pointer;
}
.downArrow_active {
    transform: rotate(180deg);
    transition: 0.5s;
}
.info_tvl {
    max-width: 187px !important;
    padding: 16px 10px !important;
}
.info_transactions {
    max-width: 123px !important;
    padding: 16px 8px !important;
    margin-right: 0 !important;
}
.pool_header .pool_menus .css-bbq5bh .css-f5brtt-BaseToolTop {
    width: 165px;
}
.pool_header .pool_menus .css-bbq5bh .css-f5brtt-BaseToolTop .css-9k1wf-Bubble {
    padding: 5px;
}
/*** Info Pool Detail End ***/

/*** Faucet Page Start ***/
.FaucetToken .form-control:disabled {
    background-color: var(--system-color-100);
    opacity: 1;
}
.faucetPage .token_block {
    max-width: 78% !important;
}
.faucetToken span,
.faucetToken p {
    padding: 12px 6px !important;
    font-size: 14px !important;
}
.getTokenIcon {
    margin-right: 5px;
}
.getTokenIcon {
    height: auto !important;
    filter: none !important;
}
.break-spaces {
    white-space: break-spaces !important;
}
.faucetTableHead {
    border-color: var(--system-color-400);
}
.action_add:hover span,
.action_add .tokenDropDown:focus span {
    color: var(--primary-color-300);
    transition: 0.3s;
    cursor: pointer;
}
.Testnet_Faucet {
    color: #cbccd1 !important;
}
/*** Faucet Page End ***/

/*** Input Type number hide arrow Start ***/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
/*** Input Type number hide arrow End ***/

/*** Trade Routing Start ***/

.token_block.routeBig {
    max-width: 860px;
    width: 100%;
    padding-bottom: 0;
    /* margin-inline-start: 20px; */
    flex-shrink: 0;
    transition: 0.3s;
    padding: 25px !important;
}
.token_block .flex_title a {
    background: var(--system-color-100);
    border-radius: 12px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.token_block .flex_title a img {
    height: 16px;
}
.token_block.routeBig .wallet_desc {
    width: 440px;
}
.inner_area.routePage {
    max-width: 100%;
}
.inner_area.routePage .trade_info {
    display: flex;
}
.swapRouting {
    max-width: 1300px;
}
.routePool {
    margin-right: 10px;
}
.routePoolHeading {
    font-size: 12px;
}
.routePoolDesc {
    font-size: 11px;
}
.tradeRouteBanner img {
    width: 60px;
}
.tradeRouteEmpty,
.ALposition {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.fetch_price {
    background: var(--system-color-100);
    border-radius: 12px;
    padding: 13px 13px;
    align-items: center;
    justify-content: space-between;
    min-width: 230px;
    margin-bottom: 10px;
}
.fetch_price .spinner_block {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-between;
}
.fetch_price .spinner_block span {
    margin-left: 5px;
}
.fetch_price .spinner_block img {
    width: 10%;
}
.fetch_price .fetch_price_loader i {
    font-size: 12px;
}
.isRouteLoad img {
    width: 65% !important;
    margin-bottom: 8px;
}
/*** Trade Routing End ***/

/*** Coming Soon Start***/
.ComingSoon {
    opacity: 0.3;
}
.ComingSoon h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.ComingSoonEffect {
    opacity: 0.3;
    /* filter: blur(3px); */
    pointer-events: none;
}

.tradeRouteLoader {
    filter: blur(10px);
    pointer-events: none;
}
.comingSoonBanner {
    pointer-events: none;
    /* background-color: rgba(51, 51, 51, 0.602); */
    padding: 100px 200px;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.comingSoonBanner p {
    font-size: 2rem;
    font-weight: 600;
}
.comingSoonBanner span {
    font-size: 2.5rem;
    color: var(--primary-color-300);
    font-weight: 600;
}
.appLoad {
    display: flex;
    justify-content: center;
}
.appLoad img {
    width: 75%;
    min-width: 150px;
}
.deviceAccess {
    padding-top: 10px;
}
.deviceAccess p {
    font-size: 18px;
    padding: 0px !important;
}

/*** Coming Soon End***/

/*** Feedback button Start***/
.Feedback {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 24px;
    border: none;
    outline: none;
    background-color: var(--secondary-warning-100);
    color: white;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 10px;
}

.feedBckDesc {
    transition: opacity 1000ms;
    opacity: 0;
    color: white;
    white-space: nowrap;
    font-size: 0;
    transition: 0.3s;
}
.Feedback:hover .feedBckDesc {
    transition: opacity 700ms, width 1ms linear 270ms,
        font-size 1ms linear 270ms;
    opacity: 1;
    font-size: 14px;
    margin-right: 5px;
    font-weight: 600;
    transition: all 400ms cubic-bezier(0.62, 0.1, 0.5, 1);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.FeedBckSet {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

/*** Feedback button End***/

/*** Trade Page New Design Start***/
.sectionTxtPosition {
    position: relative;
    top: -2px !important;
    right: 3px;
}
.sectionLabelPosition {
    position: relative;
    top: -15px !important;
    right: 3px;
}
.chooseToken {
    position: relative;
}
.wallet_info_section {
    background: var(--system-color-100);
    border-radius: 12px;
    padding: 3px 13px;
    margin-bottom: 10px;
}
.balanceRatio {
    width: 75% !important;
}
.inputLoad {
    filter: grayscale(1);
    opacity: 0.4;
}
/*** Trade Page New Design End***/

/*** ReactTooltip Start***/
.tradeRouteCustomCss {
    background-color: var(--system-color-200) !important;
    color: var(--system-color-600) !important;
    font-size: 12px !important;
    padding: 10px !important;
    max-width: 270px;
}

.tradeRouteCustomCss::after {
    background-color: var(--system-color-200) !important;
    border: 1px solid var(--system-color-400) !important;
}

.tooltipCustomBoosted {
    background: var(--system-color-200) !important;
    color: var(--system-color-600) !important;
    padding: 10px !important;
    border: 1px solid var(--system-color-400) !important;
    border-radius: 10px !important;
    width: max-content !important;
    box-shadow: 0 0 10px 0 #f1522369 !important;
}
.tooltipCustomBoosted::before {
    display: none;
}
.tooltipCustomBoosted::after {
    display: none;
}

.tooltipCustomLiquidity {
    background: var(--system-color-200) !important;
    color: var(--system-color-500) !important;
    border: 1px solid var(--system-color-400) !important;
    border-radius: 12px !important;
    padding: 10px 10px 0 10px !important;
}
.tooltipCustomLiquidity::after {
    background-color: var(--system-color-200) !important;
    border: 1px solid var(--system-color-400) !important;
}

.info_Pool_Tool_tip {
    max-width: 430px !important;
}

.borderClass {
    border: 1px solid var(--system-color-400) !important;
    border-radius: 12px;
}

.readMore {
    color: var(--primary-color-300) !important;
    text-decoration: underline;
}
/*** ReactTooltip End***/

/*** React Custom Range Slider Start ***/
.range-slider {
    width: 100%;
}
.range-slider__range {
    -webkit-appearance: none;
    width: calc(100% - (73px));
    height: 10px;
    border-radius: 5px;
    background: #d7dcdf;
    outline: none;
    padding: 0;
    margin: 0;
    position: relative;
}
.range-slider__range::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 0%;
    /* background-image: url(../images/svg/Intersection\ 1.svg); */
    background: var(--primary-color-300);
    border-radius: 50%;
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    z-index: 221;
    top: -2px;
    left: 0px;
    background-position: right;
}
.range-slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 0%;
    /* background-image: url(../images/svg/Intersection\ 2.svg); */
    background: var(--primary-color-300);
    border-radius: 50%;
    background-size: contain;
    position: relative;
    background-repeat: no-repeat;
    z-index: 222;
    top: -0px;
    background-position: right;
    right: -0px;
    cursor: pointer;
    -webkit-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
    outline: none;
}

.range-slider__range::-webkit-slider-thumb:hover {
    cursor: pointer;
}

.range-slider__range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #2c3e50;
    cursor: pointer;
    -moz-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}

.range-slider__value {
    display: inline-block;
    position: relative;
    width: 60px;
    color: var(--system-color-600);
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    background: #2c3e50;
    padding: 5px 10px;
    margin-left: 8px;
}
.range-slider__value:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid #2c3e50;
    border-bottom: 7px solid transparent;
    content: '';
}

.range-slider {
    position: relative;
}
.rangeProgress {
    position: absolute;
    top: 22px;
    height: 10px;
    background: var(--primary-color-300);
    border-radius: 5px;
}

/*** React Custom Range Slider End ***/

.toolTipLiquidity div div div {
    text-align: center;
    max-height: 350px;
    overflow: auto;
    /* display: flex !important; */
    /* padding: 7px 10px 7px 20px !important; */
    color: var(--system-color-500);
    background: var(--system-color-200);
}
.toolTipLiquidity div div div:last-child {
    padding-bottom: 0 !important;
}

.toolTipLiquidity div div div::-webkit-scrollbar,
.headerTransaction::-webkit-scrollbar,
.additionPart_Block::-webkit-scrollbar,
.plReserve_Block::-webkit-scrollbar,
.trades_table::-webkit-scrollbar {
    height: 5px;
}
.toolTipLiquidity div div div::-webkit-scrollbar,
.headerTransaction::-webkit-scrollbar,
.additionPart_Block::-webkit-scrollbar,
.plReserve_Block::-webkit-scrollbar,
.trades_table::-webkit-scrollbar {
    height: 8px;
    background: #6969692f;
}
.toolTipLiquidity div div div::-webkit-scrollbar-thumb,
.headerTransaction::-webkit-scrollbar-thumb,
.additionPart_Block::-webkit-scrollbar-thumb,
.plReserve_Block::-webkit-scrollbar-thumb,
.trades_table::-webkit-scrollbar-thumb {
    height: 8px;
    background: #dedede52;
    border-radius: 20px;
}

.toolTipLiquidity div div div::-webkit-scrollbar-track,
.headerTransaction::-webkit-scrollbar-track,
.additionPart_Block::-webkit-scrollbar-track,
.plReserve_Block::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--system-color-400);
}

.toolTipLiquidity div div div::-webkit-scrollbar,
.headerTransaction::-webkit-scrollbar,
.additionPart_Block::-webkit-scrollbar,
.plReserve_Block::-webkit-scrollbar,
.trades_table::-webkit-scrollbar {
    width: 5px;
    background-color: var(--system-color-400);
    border-radius: 8px;
}

.toolTipLiquidity div div div::-webkit-scrollbar-thumb,
.headerTransaction::-webkit-scrollbar-thumb,
.additionPart_Block::-webkit-scrollbar-thumb,
.plReserve_Block::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--primary-color-300);
}

.headerTransaction::-webkit-scrollbar-thumb,
.additionPart_Block::-webkit-scrollbar-thumb,
.plReserve_Block::-webkit-scrollbar-thumb,
.trades_table::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    cursor: pointer;
}

/*** React UI LIGHT THEME Start ***/
.Light {
    background: var(--system-color-600) !important;
    color: #000000 !important;
}
.Light .navbar-nav .nav-link {
    color: #000000 !important;
}
.Light .navbar-nav .nav-link.active,
.Light .navbar-nav .nav-link:hover {
    background-color: var(--system-color-500) !important;
}
.themeView {
    border-bottom: 1px solid var(--system-color-400);
}
/*** React UI LIGHT THEME End ***/

/*** LockDrop PAGE  Start ***/
/*** LockDrop Deposits Start ***/
.ld_align {
    display: grid !important;
    grid-template-columns: 0.8fr 0.8fr 1.1fr 1.05fr 1.3fr;
    grid-gap: 10px;
}
/* .LdDeposits_inner_area {
  max-width: 1096px;
  margin: 0 auto;
} */
.deposit_block,
.confirmPair {
    padding-top: 20px;
}
.empty_deposit_block {
    padding: 33px !important;
    display: flex;
    justify-content: center !important;
}
.deposit_block .tokens_block {
    background-color: var(--system-color-300);
    padding: 22px;
    border-radius: 9px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.deposit_block .PositionBD {
    background-color: var(--system-color-300);
    border-radius: 9px;
    border: 1px solid var(--system-color-400);
}
.deposit_block .PositionBD tbody tr td {
    padding: 12px;
}
.positionBdBoosted {
    width: fit-content;
    padding: 4px 7px;
    border-radius: 44px;
    gap: 3px;
    background-color: var(--primary-color-300);
}
.positionBdBoosted .boost {
    font-size: 10px;
}
.positionBdBoosted img {
    width: 12px !important;
    height: 12px !important;
}
.bootToolTip tbody td img {
    width: 12px !important;
    height: 12px !important;
}
.bootToolTip tbody td span {
    color: var(--system-color-600);
    font-size: 10px;
    font-weight: 400;
}
.bootToolTip thead th,
.cellBlocks .report_card_title span {
    font-size: 12px;
    font-weight: 500;
}
.deposit_block .tokens_block p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.deposit_block .tokens_block .img_comb img {
    width: 25px;
}
.apr_percentage .apr_title {
    color: #9ba5b4;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}
.Price_PRC span,
.pool_details_desc span,
.stake_block_title span,
.reserve_pool_title span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.LdClaim {
    background-color: var(--primary-color-300) !important;
    border: 1px solid var(--primary-color-300);
}
.LdClaim:hover {
    background-color: transparent !important;
    border: 1px solid var(--primary-color-300);
}
.collapsed_block {
    background: var(--system-color-100);
    border: 1px solid var(--system-color-300);
    border-radius: 0px 0px 9px 9px;
    padding: 22px;
}
.deposit_collapse_block {
    border-radius: 9px 9px 0px 0px !important;
}
.LdWithdraw {
    border: 1px solid var(--primary-color-300) !important;
}
/*** LockDrop Deposits End ***/
/*** LockDrop Featured Pools start ***/
.lockDrop_active {
    background: var(--secondary-warning-100) !important;
    color: #000000 !important;
}

.Featured_cards .row .cards_details {
    padding-top: 17px;
}
.Featured_cards .card {
    background: var(--system-color-300);
    mix-blend-mode: normal;
    border: 0.5px solid #414141;
    border-radius: 8px;
}
.cards_details .img_comb img,
.LdPoolsDetails .block3 .img_comb img {
    width: 33px !important;
}
.LdPoolsDetails .token_block {
    min-width: 800px;
}
.cards_details .assetsTitle,
.Check-eligibility .Ce_title,
.Com_Reward .reward {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
}
.cards_details .card-body {
    padding: 21px;
    width: 350px;
}
.Explore_Pools button {
    padding: 6px !important;
    width: 164px;
}
.featured_pool_search {
    border: 1px solid var(--system-color-400) !important;
    border-radius: 12px;
    padding: 14px 5px 14px 35px !important;
}
.featured_pl_option .active_option {
    background: var(--system-color-100);
    border-radius: 8px;
    padding: 10px;
    color: var(--system-color-600);
}
.featured_pl_option span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--system-color-500);
}

/*** LockDrop Featured Pools End ***/
/*** LockDrop Pools Details Start ***/
.LdPoolsDetails {
    max-width: 800px;
    /* margin: 0 auto; */
    margin-left: 10%;
}
.LdPoolsDetails .token_block {
    max-width: 800px !important;
}
.LdPoolsDetails .main_title p {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}
.LdPoolsDetails .main_title {
    padding-bottom: 26px !important;
}
.LdPoolsDetails .block3 {
    padding: 29px !important;
}
.Pl_Details_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: end;
}
.Estimated_rewards {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: var(--system-color-500);
}
.pl_details_desc {
    background: var(--system-color-100);
    mix-blend-mode: normal;
    border: 0.5px solid var(--primary-color-300);
    border-radius: 8px;
    padding: 10px;
}
.LdPoolsDetails .block3 .add_dialogue {
    max-width: 297px !important;
}
.LdPoolsDetails .block3 .add_dialogue .add_info span {
    line-height: 16px !important;
    font-weight: 500 !important;
}
.Ld_example p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 15px;
}
.ld_example_modal_root {
    filter: blur(12px);
}
.LdPoolsDetails .RangeValue {
    font-size: 20px !important;
}
.LdSubMenu {
    display: none !important;
}
.LdMenuOption:hover .LdSubMenu {
    display: block !important;
    transform: translate(58%, 18%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: auto;
    max-width: 115px;
    border: 1px solid #4c3f10;
    border-radius: 12px;
    background: #332a0b;
    padding: 5px;
    z-index: 1;
}

.LdSubMenu a,
.frame_date {
    color: var(--system-color-500) !important;
}
.LdMenuOption #a li:hover {
    background: none !important;
    border-radius: 0px !important;
}
.LdMenuOption #a li:hover span,
.yellow_Head {
    color: var(--secondary-warning-100) !important;
}
.LdMenuOption #a li {
    border-bottom: 1px solid #4c3f10;
}
.LdMenuOption #a li:last-child {
    border: none !important;
}
.ld_pool_details {
    background: #332a0b !important;
    border: 0.5px solid var(--secondary-warning-100);
    border-radius: 8px;
}
.ld_pool_details .reward {
    margin-bottom: 10px !important;
}
.Additional_bonus span {
    color: var(--secondary-warning-100);
}
.Final_Bonus {
    padding-top: 95px !important;
}
.Final_Bonus p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}
.Lock_Period {
    margin-top: 20px;
}
.Lock_Period_boost .bonus_range_point1 {
    left: 99px;
}
.Lock_Period_boost .bonus_range_point2 {
    left: 202px;
}
.Lock_Period_boost .bonus_range_point3 {
    left: 304px;
}
.Lock_Period_boost .boost_bonus_range::after {
    top: -3px;
}
.add_modal_warn p {
    color: #ee3131;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(12px);
    z-index: 300 !important;
}
.pool_details_block_events {
    filter: blur(2px);
}
/*** LockDrop Pools Details End ***/

/*** LockDrop VRSW Lockdrop Start ***/
.LdDeposits_container {
    max-width: 1396px !important;
}

.vrsw_block {
    padding: 35px;
    overflow: hidden;
    height: 100%;
}
.vrsw_block h1 {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    color: #000000;
}
.vrsw_block span {
    color: var(--system-color-600);
}
/* .Ld_vrsw_example {
  padding-top: 32px;
  padding: 16px 0px;
} */
.Ld_vrsw_example p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 13px;
    color: #000;
}
.vrsw_report {
    background: var(--system-color-200);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 23px 20px;
}
.vrsw_report h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px !important;
    line-height: 32px;
    color: var(--system-color-600);
}
.report_desc_amount h1 {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 40px;
}
.vrsw_report_event {
    height: 48px !important;
    width: 245px !important;
}
.vrsw_report_event span.custom_btn.pointer.swapTokenButton {
    background-color: var(--secondary-warning-100);
    color: #000;
    border: 1px solid var(--secondary-warning-100);
}
.vrsw_report_event:hover span.custom_btn.pointer.swapTokenButton {
    color: var(--system-color-600);
}

.Explore_Pools .vrsw_report_event:hover {
    border: 1px solid var(--secondary-warning-100) !important;
    background-color: var(--secondary-warning-100);
}
.Explore_Pools .vrsw_report_event:hover span {
    color: #000 !important;
}
.Explore_Pools .vrsw_report_event {
    border: 1px solid var(--secondary-warning-100) !important;
}
.vrsw_learn span {
    border: 1px solid var(--secondary-warning-100) !important;
}
.vrsw_learn:hover span {
    background-color: var(--secondary-warning-100);
    color: #000 !important;
}
.vrsw_learn_active span {
    background-color: var(--secondary-warning-100);
    color: #000 !important;
}
.vrsw_learn_active:hover span {
    background: transparent;
    color: #fff !important;
}

.report_card_title span,
.VRSW_staked {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.APR_Head {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

/* .ld_status_block {
  padding-left: 15px;
  padding-right: 0px;
} */
.report_numeric_frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 54.29px;
    height: 40px;
    background: var(--system-color-400);
    border: 1px solid var(--system-color-500);
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 1;
    cursor: pointer;
}
.active_numeric_frame {
    background: #ffd438 !important;
    box-shadow: 0px 0px 12px var(--secondary-warning-100),
        inset 0px 0px 9px #ffa577 !important;
    color: #000 !important;
}
.phase2_pd {
    padding-top: 16px;
    padding-bottom: 34px;
}
.buy_VRSW {
    overflow: initial;
    margin-right: 34px !important;
}
.buy_VRSW .btn-black {
    padding: 6px !important;
}
/*** LockDrop VRSW Lockdrop End ***/

/*** LockDrop Boost Calculator Start ***/
/* .ld_side_block_boost {
  position: sticky;
  top: 8%;
} */
.Boost_calculator_block .token_block {
    padding: 15px !important;
    /* position: absolute !important; */
    /* min-width: 384px; */
}
.Boost_calculator_block .token_block .add_info span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.modalCoins {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 16px;
}
.boost_bonus span,
.ld_pool_details .reward,
.ld_pool_details .reward_amount,
.report_desc_number h1,
.Price_PRC span {
    color: var(--secondary-warning-100);
}
.boost_bonus_range {
    -webkit-appearance: none;
    width: calc(100% - (73px));
    height: 10px;
    border-radius: 5px;
    background: var(--system-color-400);
    outline: none;
    padding: 0;
    margin: 0;
    position: relative;
}
.boost_bonus_range::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 0%;
    background: var(--secondary-warning-100);
    border-radius: 50%;
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    z-index: 221;
    top: -3px;
    left: 0px;
    background-position: right;
}

.bonus_range {
    background: var(--secondary-warning-100) !important;
}

.boost_bonus_range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 0%;
    background: var(--secondary-warning-100);
    border-radius: 50%;
    background-size: contain;
    position: relative;
    background-repeat: no-repeat;
    z-index: 222;
    top: -0px;
    background-position: right;
    right: -0px;
    cursor: pointer;
    -webkit-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
    outline: none;
}

.boost_bonus_range::-webkit-slider-thumb:hover {
    cursor: pointer;
}

.rangeProgress.bonus_range {
    top: 50% !important;
    transform: translateY(-50%) !important;
}
.boost_bonus_range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #2c3e50;
    cursor: pointer;
    -moz-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}

.isBoost_Range {
    width: 15px;
    height: 15px;
    border-radius: 0%;
    background: var(--secondary-warning-100);
    border: 4px solid var(--system-color-100) !important;
    border-radius: 50%;
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%);
    background-position: right;
    box-sizing: content-box;
}
.selected_range_point {
    background: var(--system-color-400) !important;
    border: 4px solid var(--system-color-100) !important;
}
.red_text .share_btn img {
    filter: none !important;
    height: 12px;
}
.VRSW_Price_range_point1 {
    right: -4px;
    top: 11px;
}
/*** LockDrop Boost Calculator End ***/

/***BoostCalculator Slider Start***/
.slider-horizontal {
    position: relative;
}
.slider-horizontal .rangeslider-horizontal .rangeslider__fill {
    background-color: var(--secondary-warning-100) !important;
}
.slider-horizontal .rangeslider {
    background: var(--system-color-400);
}
.slider-horizontal .rangeslider .rangeslider__handle {
    background-color: var(--secondary-warning-100) !important;
    border: none;
    box-shadow: none;
    width: 22px;
    height: 22px;
    border: 3px solid var(--system-color-100) !important;
    z-index: 2;
}
.slider-horizontal .rangeslider-horizontal .rangeslider__handle:after {
    display: none;
}
.slider-horizontal .rangeslider-horizontal {
    height: 10px;
}
.slider-horizontal .rangeslider,
.slider-horizontal .rangeslider .rangeslider__fill {
    box-shadow: none;
    cursor: pointer;
}
.slider-horizontal .rangeslider .rangeslider__fill::after {
    content: '';
    cursor: pointer;
    width: 22px;
    height: 22px;
    background: var(--secondary-warning-100);
    border-radius: 50%;
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    top: -6px;
    left: -1px;
    background-position: right;
    border: 3px solid var(--system-color-100) !important;
}
.slider-horizontal .VRSW_Price_range_point1 {
    right: -1px;
    top: 5px;
    z-index: 1;
}
.slider-horizontal .bonus_range_point1 {
    left: 30.33%;
    z-index: 1;
    border: 3px solid var(--system-color-100) !important;
}
.slider-horizontal .bonus_range_point2 {
    right: 30.33%;
    z-index: 1;
    border: 3px solid var(--system-color-100) !important;
}
.rangeslider .rangeslider__handle-tooltip span {
    margin-top: 12px;
    display: inline-block;
    line-height: inherit;
    font-weight: 600;
}
.rangeslider .rangeslider__handle-tooltip {
    width: 75px !important;
}
/***BoostCalculator Slider End***/

/*** LockDrop PAGE  End ***/

/*** Stake Page Start ***/
/*** Stake Page DOA block Start ***/
.stake_block_title span,
.ad_Indirect {
    color: #999999;
}
.stake_block_title p,
.GV_forum {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}
.stake_block_img .img_comb img {
    height: 16px;
    width: 16px;
}
.stake_lock img {
    width: 16.66px !important;
    height: 27px !important;
}
.stake_desc_block {
    background-color: var(--system-color-300);
    padding: 16px;
    border-radius: 9px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--system-color-400);
}
.voting_data {
    font-weight: 700 !important;
    color: #f15223;
}
.voting_token_value {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: var(--system-color-600) !important;
}
.voting_power_blocks {
    margin-top: 22%;
}
.stake-btn {
    padding: 12px;
    color: var(--system-color-600);
    font-size: 16px;
    background-color: var(--system-color-100);
    min-height: auto;
    border-radius: 12px;
    font-weight: 500;
    border: 1px solid var(--primary-color-300);
}
.voting_power {
    height: 223px;
}
.dao_dashboard {
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 9px;
    padding: 20px;
}
.stake-block {
    font-weight: 700;
    font-size: 24px !important;
    line-height: 32px !important;
}
.boost_aOn .stake-block {
    color: #ffffff;
}
.stake_virtu_part {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
}
.stake_chart_icons {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}
.chart_icons1 {
    background: #f15223;
    box-shadow: 0px 0px 4px #f15223;
}
.chart_icons2 {
    background: #f1d023;
    box-shadow: 0px 0px 4px #ffd335;
}

.chart_icons3 {
    background: #50af95;
    box-shadow: 0px 0px 4px #50af95;
}
.chart_icons4 {
    background: #00a3ff;
    box-shadow: 0px 0px 4px #459bff;
}

.chart_icons5 {
    background: #c547e5;
    box-shadow: 0px 0px 4px #c547e5;
}
.token_rpt_desc {
    font-size: 13px !important;
}
.stake_parts_border {
    border: 1px solid var(--system-color-400);
}
.Vertical_Line {
    padding-right: 20px;
    border-right: 1px solid var(--system-color-400);
}
.Vertical_Line_Left {
    padding-left: 10px;
    border-left: 1px solid var(--system-color-400);
}
.apexcharts-legend-marker {
    box-shadow: 0px 0px 4px;
}
.GOV_forum button .filter-close_img {
    width: 15px;
}
.Boost_Cal .slider-horizontal .rangeslider-horizontal .rangeslider__fill,
.Boost_Cal .slider-horizontal .rangeslider .rangeslider__handle {
    background-color: var(--primary-color-300) !important;
}
.Boost_Cal .slider-horizontal .rangeslider .rangeslider__fill::after,
.Boost_Cal .slider-horizontal .isBoost_Range {
    background-color: var(--primary-color-300);
}
.Boost_Cal .slider-horizontal .rangeslider .rangeslider__handle,
.Boost_Cal .slider-horizontal .isBoost_Range,
.Boost_Cal .slider-horizontal .rangeslider .rangeslider__fill::after {
    border: 3px solid var(--system-color-300) !important;
}
.Boost_Cal .slider-horizontal .bonus_range_point1 {
    left: 31.6%;
}
.Boost_Cal .slider-horizontal .bonus_range_point2 {
    right: 31.6%;
}
.Boost_Cal .slider-horizontal .rangeslider .rangeslider__fill::after {
    left: 0px;
}
.Boost_Cal .slider-horizontal .rangeslider .rangeslider__handle,
.Boost_Cal .slider-horizontal .rangeslider .rangeslider__fill::after {
    width: 21px;
    height: 21px;
}
.Boost_amount {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
}
.boost_aOn {
    background: #4d1d10 !important;
    border: 1px solid #f15223;
    border-radius: 12px;
}
.Boosted {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #f15223;
}
.VRSW_boosted_block {
    margin-right: 70px;
}
.action_btns a:focus .stake-btn {
    background-color: var(--system-color-100) !important;
}
.stake_align {
    /* display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
    grid-gap: 5px;
}
.stake_align_position {
    /* display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
    grid-gap: 5px;
}
.stake_block_align {
    display: grid !important;
    grid-template-columns: 1fr 0.8fr 1.2fr 1.15fr 1.45fr;
    grid-gap: 10px;
}
.stake_block_align_VRSW {
    display: grid !important;
    grid-template-columns: 2fr 3fr 3fr 317px;
    /* grid-template-columns: 2fr 2fr 2fr 2.5fr 317px; */
    grid-gap: 5px;
}
.stake_block_align_VRSW.flex-box > div,
.stake_align_position.flex-box > div {
    flex: 1;
}
.VRSW_Stake .flex-box > div,
.stake_align_position.flex-box > div {
    flex: 1;
    width: calc(100% / 7);
}
.deposit_block.VRSW_Stake .tokens_block {
    padding: 12px !important;
}
.VRSW_Stake .actionsEvents.flex-box {
    padding: 0 !important;
}
.actionsEvents.flex-box > div,
.CollapseActionsEvents.flex-box > div,
.stakedLpActionsEvents.flex-box > div {
    justify-content: flex-end;
    width: 100%;
}
.actionsEvents.flex-box,
.CollapseActionsEvents.flex-box,
.stakedLpActionsEvents.flex-box {
    width: calc(100% / 7 * 2) !important;
    flex: 2 !important;
}
.CollapseActionsEvents .LdWithdraw {
    margin-right: 22px;
}
.CollapseActionsEvents .PositionStaked {
    margin-right: 33px;
}
.stakedLpActionsEvents .stakedLpClaim {
    margin-right: 25px;
}
.simpleLpStake {
    margin-right: 0px !important;
}
.simpleLpStake .LdWithdraw {
    margin: 0 7px !important;
}
.Gov_stake_overview {
    display: grid !important;
    grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
    grid-gap: 10px;
}
.gov-stake-btn {
    padding: 8px 24px;
    font-size: 12px;
    border-radius: 12px;
    line-height: 16px;
}
.gov_dashboard {
    color: var(--primary-color-300) !important;
}
.gov_col .LdWithdraw {
    background-color: transparent;
}
.gov_white_lock {
    width: 16px !important;
}
.gov_stake_align {
    display: grid !important;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1.3fr;
    grid-gap: 10px;
}

.gov_stake_block_align {
    display: grid !important;
    grid-template-columns: 1fr 1.2fr 1fr 1fr 1.55fr;
    grid-gap: 10px;
}
.gov_boosted {
    display: flex;
    border: 1px solid var(--primary-color-300);
    border-radius: 25px;
    padding: 2px 5px;
}
.fire_icon {
    width: 10px !important;
    height: 12px !important;
}
.pie_area,
.pie_area_simple,
.empty_pie_area ,.stake_donut_area{
    position: relative;
}
.pie_area::before {
    position: absolute;
    left: 376px;
    right: 0;
    width: 110px;
    height: 110px;
    border: 2px dashed #5d6588;
    z-index: 9;
    content: '';
    border-radius: 50%;
    top: 158px;
    transform: translateY(-50%);
}
.stake_donut_area::before{
    position: absolute;
    left: 58px;
    right: 0;
    width: 110px;
    height: 110px;
    border: 2px dashed #5d6588;
    z-index: 9;
    content: '';
    border-radius: 50%;
    top: 158px;
    transform: translateY(-50%);
}
.empty_pie_area::before {
    position: absolute;
    left: 109px;
    right: 0;
    width: 110px;
    height: 110px;
    border: 2px dashed #5d6588;
    z-index: 9;
    content: '';
    border-radius: 50%;
    top: 158px;
    transform: translateY(-50%);
}
.pie_area_stakingFull::before {
    position: absolute;
    right: 208px;
    width: 110px;
    height: 110px;
    border: 2px dashed #5d6588;
    z-index: 9;
    content: '';
    border-radius: 50%;
    top: 151px;
    transform: translateY(-50%);
}
.pie_area_simple::before {
    position: absolute;
    left: 357px;
    right: 0;
    width: 110px;
    height: 110px;
    border: 2px dashed #5d6588;
    z-index: 9;
    content: '';
    border-radius: 50%;
    top: 43.5%;
    transform: translateY(-50%);
}
.pie_Chart_Info {
    color: rgb(255, 255, 255) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: 'Plus Jakarta Sans' !important;
}
.pie_chart_area .apexcharts-legend,
.pie_area .apexcharts-legend {
    width: 280px;
    max-height: 176.5px !important;
}
/* .pie_chart_area .apexcharts-theme-light > .apexcharts-svg{
    width: 260px !important;
    height: 180px !important;
    position: absolute;
    right: 100px;
}
.pie_chart_area .apexcharts-theme-light .apexcharts-svg > g{
    transform: translate(0 , 0) !important;
} */
/*** Stake Page DOA block eND ***/
/*** Stake Page End ***/

/*** Boost CL Toggle Switch Start ***/
.switches-container {
    width: 12rem;
    position: relative;
    display: flex;
    padding: 3px;
    position: relative;
    background: var(--system-color-100);
    line-height: 1.7rem;
    border-radius: 3rem;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--system-color-600);
}

/* switch highlighters wrapper (sliding left / right) 
  - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.15rem 0.4rem 0.15rem 0rem;
    z-index: 3;
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
    border-radius: 3rem;
    background: #f15223;
    height: 100%;
    width: 100%;
}

/* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status 
*/
.switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: #fff;
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
    will-change: opacity;
    position: absolute;
    top: 5px;
    left: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
    transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
    transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container
    input:nth-of-type(1):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(1) {
    opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container
    input:nth-of-type(2):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(2) {
    opacity: 1;
}
/*** Boost CL Toggle Switch End ***/

/*** AirDrop PAGE  Start ***/
.Ad_block {
    padding: 30px;
    overflow: hidden;
    height: 100%;
}
.Ad_block h1 {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
    color: #000;
}
.FeaturedCards {
    background: linear-gradient(
        180deg,
        #ffd335 0%,
        #fff0bc 48.23%,
        #ffd335 100%
    );
    border: 1px solid var(--system-color-400);
    border-radius: 20px;
}
.Ad_example p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}
.Ad_Desc {
    width: 88%;
}
.Ad_Desc_test {
    width: 60%;
}
.ad_rules {
    font-size: 14px !important;
    line-height: 20px !important;
}
.ad_rules span {
    color: var(--primary-color-300);
    font-weight: 700;
}
.Check-eligibility {
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    padding: 20px;
}
.Check-eligibility .LdClaim {
    width: 75%;
}
.Check-eligibility .reward {
    color: #36e25b;
}
.Com_Reward .reward {
    color: green;
}
.Check-eligibility .spinner_block {
    margin-top: 0;
    margin-bottom: 0;
}
.Check-eligibility .tradeRouteBanner img {
    width: 50px;
}
.adBlockLoad {
    padding-top: 20%;
    padding-bottom: 20%;
}
.Check-eligibility .notRewarded {
    color: #ee3131;
}
.Check-eligibility .connect_another {
    width: 90%;
}
.Check-eligibility .Ad_walletDesc {
    margin-bottom: 15px;
}
.Ad_List_block {
    background-color: var(--system-color-300);
    padding: 10px 15px;
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    position: relative;
}
.Ad_Polygon_table {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--system-color-600);
}
.Ad-table {
    background: var(--system-color-200);
    border-radius: 8px;
}
.trades_table::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 74%;
    left: 32px;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        var(--system-color-100) 90%
    );
    pointer-events: none;
    width: 94.5%;
    height: 3em;
    border-radius: 12px;
}
.In_activated_desc {
    color: var(--secondary-danger-100);
}
.trades_table {
    max-height: 115px;
    overflow: auto;
}
.trades_table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 0%);
    background: var(--system-color-200);
}
.trades_table::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 0%);
    background: var(--system-color-200);
    border: 0.5px solid #a2a2a9;
}
.Ad-table .row .col {
    padding: 0.57rem;
}
.ad_pool_title {
    border: 1px solid var(--system-color-400);
    border-radius: 8px;
}
.eligible_trades {
    background: var(--system-color-300);
    border: 1px solid #36e25b;
    box-shadow: inset 0px 0px 47px -2px rgba(53, 222, 90, 0.27);
    border-radius: 20px;
    padding: 30px;
    position: relative;
}
.non_eligible_trades {
    background: var(--system-color-300);
    border: 1px solid #ee3131;
    box-shadow: inset 0px 0px 24px #ee3131;
    border-radius: 20px;
    padding: 30px;
    position: relative;
    color: #ee3131;
}
.eligible_trades .Ce_title {
    font-size: 16px;
}
/*** AirDrop PAGE  End ***/
/*** Staking Page Start***/
.StakeVRSW .form-control {
    background: var(--system-color-300);
    border: 1px solid var(--system-color-400);
    border-radius: 9px;
}
.StakeVRSW input:focus {
    background: var(--system-color-300);
}
.unStakeBtn {
    margin-top: 50%;
}
.unStakePart {
    margin-top: 29px;
}
.unStakeBtn .btn-dark:focus {
    box-shadow: none;
}
.EpochBlock {
    font-size: 12px !important;
    /* font-weight: 600 !important; */
    font-family: 'Plus Jakarta Sans' !important;
}
.next_Epoch {
    padding: 5px 18px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    font-family: 'Plus Jakarta Sans';
}
.tokenDropDown:hover .next_Epoch {
    background-color: var(--primary-color-300);
    color: var(--system-color-600);
}
/*** Staking Page End***/
/*** Community AirDrop Start***/
.Com_ad h1 {
    font-size: 40px;
    margin-bottom: 10px;
}
.Ad_Desc .points_list {
    list-style: disc;
    color: #000;
    padding-left: 20px;
}
.Ad_Desc .points_list img {
    width: 14px;
}
.Ad_Desc .points_list li a {
    color: #000;
}
.rewards_list,
.not_rewards {
    color: #000000;
    background: linear-gradient(#ffd335 0%, #ffd335 100%);
}
.rewards_list .Com_ad {
    padding: 70px;
}
.rewards_list .Com_ad p {
    font-size: 16px;
}
.not_rewards h1 {
    font-weight: 400;
    font-size: 30px;
}
.not_rewards .total_reward span {
    font-weight: 700;
}
.not_rewards .btn-black:hover {
    color: #000000;
}
.Claimed_desc p {
    color: var(--system-color-600);
    font-size: 22px;
    font-weight: 500;
}
.Claimed_desc span {
    color: var(--primary-color-300);
    font-size: 16px;
    font-weight: 500;
}
.Claimed_VRSW_desc {
    color: #b9620f;
    font-size: 12px;
    font-weight: 700;
}
.Claimed_VRSW_desc .MetaMaskIcon {
    width: 30px;
}
.MetaMaskIcon {
    width: 24px;
}
.custom-Boosted-tooltip .PositionLpToolTip > div:not(.__react_component_tooltip) > div:last-child > div,
.custom-Boosted-tooltip .PositionLpToolTip > .__react_component_tooltip {
    width: max-content !important;
    box-shadow: 0px 0px 10px 0px #f1522369;
}
/* .custom-Boosted-tooltip .css-bbq5bh{
    display: flex;
    justify-content: center;
    align-items: center;
} */
/*** Community AirDrop End***/

img.MetaMaskIcon {
    width: 20px;
}
.vsRoute {
    padding-top: 15%;
}
.vsRoute .pool_info {
    padding: 20px 8px;
}
.vsRoute .bit_inside p {
    margin-top: -94px;
}
.vsRoute .bit_inside.arrowImg1 p {
    margin-bottom: 50px;
}
.barGraph .apexcharts-tooltip,
.barGraph1 .apexcharts-tooltip {
    /* color: #000; */
    color: var(--system-color-600) !important;
    background: var(--system-color-200) !important;
    border-radius: 10px !important;
    border: 1px solid var(--system-color-400) !important;
    /* padding: 10px;   */
    padding: 5px;
    width: max-content !important;
    box-shadow: 0px 0px 10px 0px #f1522369 !important;
}
.virtuChartReportSimple {
    width: 53% !important;
}
/*** Staking Simple Page Start***/
.setCol1 {
    width: 20%;
}
.setCol2 {
    width: 11.33%;
}
.setCol3 {
    width: 11.33%;
}
.setCol4 {
    width: 11.33%;
}
.setCol5 {
    width: 11.33%;
}
.setCol6 {
    width: 12%;
}
.setCol6 > div {
    justify-content: flex-end !important;
}
.setCol7 {
    width: 11.33%;
}
.setCol8 {
    width: 11.35%;
}
.vrsw_stake_btn {
    padding-right: 50px;
}
.Protocol_Rewards {
    height: 100%;
}
.noDataInChart {
    display: flex;
    align-items: center;
    position: relative;
}
.noDataInChart .error_main {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    width: 65%;
}
.vrsw_col_1 {
    width: 15%;
}
.vrsw_col_2 {
    width: 15%;
}
.vrsw_col_3 {
    width: 17%;
}
.vrsw_col_4 {
    width: 13%;
}
.vrsw_col_5 {
    width: 12%;
}
.vrsw_col_6 {
    width: 28%;
}
.allocationPoint {
    display: initial !important;
    z-index: 99;
}
.allocationPoint > div {
    margin-bottom: 0 !important;
    transform: translate(-50%, 0px) !important;
}
.stakeUSD {
    margin-top: 1px;
}
.stakeBlockLpTokens {
    font-size: 12px !important;
}
.claimModalDesc span {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: var(--system-color-600) !important;
}
.claimRewardModal tr td {
    padding-bottom: 0 !important;
}
.next_Epoch_btn {
    z-index: 9;
}
.pool_details {
    position: relative;
}
.epoch_details::before {
    position: absolute;
    left: auto;
    right: 41px;
    width: 110px;
    height: 110px;
    border: 2px dashed #5d6588;
    z-index: 9;
    content: '';
    border-radius: 50%;
    top: 55.5%;
    transform: translateY(-50%);
}
.pool_details .stake_virtu_part {
    width: 100%;
}
.stakingEpoch.EpochBlock {
    /* flex-direction: column !important; */
    align-items: flex-start !important;
}
.barGraph {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: -190px;
}
/*** Staking Simple Page End***/
/*** UnStackLpTokensModal start***/
.UnStackLpTokensModal .modal-dialog {
    max-width: 384px;
    width: 100%;
}
/*** UnStackLpTokensModal end***/

/*** Wallet Connector Start ***/
html,
body {
    overflow: auto !important;
    touch-action: inherit !important;
}
w3m-modal::slotted(wui-flex) {
    align-items: center !important;
}
/*** Wallet Connector End ***/
.apexcharts-xaxis-label:nth-last-child(1) {
    transform: translateX(-15px);
}

/*** Other swap options Start ***/
.crossChainPart {
    background-color: var(--system-color-100);
    border: 1px solid var(--system-color-400);
    border-radius: 12px;
    margin-top: 10px;
}
.crossChainPart p {
    padding: 8px 16px 0px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--system-color-500);
}
.crossChainPart .logoRow {
    padding: 8px 16px 0px;
    height: 32px;
    width: auto;
}
.crossChainPart .logo {
    height: 24px;
    width: auto;
    max-width: 24px;
}
.crossChainPart .action_btns {
    justify-content: end;
    padding: 5px 4px 10px;
}
.crossChainBlock {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    padding: 0px;
}
.crossChainBlock .crossChainToken {
    overflow: hidden;
    position: relative;
    flex: 1 1 0%;
    box-sizing: border-box;
    transition: background-color 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.crossChainBlock .crossChainSwap {
    margin: 0 10px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}
.crossChainBlock .crossChainToken p {
    margin: 0px;
    font-family: 'Inter var', Inter, sans-serif;
    font-size: 0.875rem;
    line-height: 1.286;
    letter-spacing: 0.01071em;
    font-weight: 700;
    padding: 3px 16px 0px;
    color: var(--system-color-600);
}
.crossChainSwap .toToken {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: visible;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.125rem;
    color: inherit;
    background-color: var(--system-color-100);
    border: 1px solid var(--system-color-400);
    z-index: 1100;
    padding: 4px;
}
/*** Other swap options End ***/
/*** Table Scrollbar Start ***/
.tableScrollBar .dataTable {
    margin-top: 6px !important;
    margin-bottom: 4px !important;
}
/* WebKit (Chrome, Safari) */
.tableScrollBar::-webkit-scrollbar {
    width: 5px !important;
    height: 8px !important;
}
.tableScrollBar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: var(--system-color-400);
}
.tableScrollBar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--primary-color-300);
}
.tableScrollBar::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-color-300);
}
/* Firefox */
.tableScrollBar {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color-300) var(--system-color-400);
}
/* Microsoft Edge */
@supports (-ms-ime-align: auto) {
    .tableScrollBar {
        width: 5px !important;
    }
    .tableScrollBar::-ms-scrollbar-track {
        border-radius: 10px;
        background-color: var(--system-color-400);
    }
    .tableScrollBar::-ms-scrollbar-thumb {
        border-radius: 5px;
        background-color: var(--primary-color-300);
    }
    .tableScrollBar::-ms-scrollbar-thumb:hover {
        background-color: var(--primary-color-300);
    }
}
/*** Table Scrollbar End ***/
/*** Staking Page Table New Design Start ***/
.new_table_block {
    background-color: var(--system-color-300);
    padding: 12px;
    border-radius: 10px;
    border: 1px solid var(--system-color-400);
}
.with_Position_tokens {
    padding-left: 0;
    padding-right: 0;
}
.with_Position_tokens .responsiveTable tbody tr {
    border: none;
}
.with_Position_tokens .responsiveTable tbody tr:not(:last-child) {
    border: none;
    border-bottom: 1px solid var(--system-color-400);
}
.new_table_block .responsiveTable tbody tr {
    border: none;
    padding: 0;
}
.new_table_block .responsiveTable tbody tr td,
.new_table_block .responsiveTable thead tr th {
    padding: 5px;
}
.new_table_block.with_Position_tokens .responsiveTable tbody tr td {
    padding-top: 18px;
}
.borderWithCollapse {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.new_table_block .tdBefore div {
    text-align: start !important;
}
.new_table_block.with_Position_tokens .responsiveTable thead tr th {
    border-bottom: 1px solid var(--system-color-400);
}
/*** Staking Page Table New Design End ***/

/*** Staking Page Pie Chart Start ***/
.donutChart_legend{
    overflow: auto;
    max-height: 150px !important;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color-300) var(--system-color-400);
}
/* WebKit (Chrome, Safari) */
.donutChart_legend::-webkit-scrollbar {
    width: 5px !important;
    height: 8px !important;
}
.donutChart_legend::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: var(--system-color-400);
}
.donutChart_legend::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--primary-color-300);
}
.donutChart_legend::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-color-300);
}
.donutChart_legend .legendSeries{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: "Plus Jakarta Sans";
}
.donutChart_legend .legendSeries .legend-marker {
    background: rgb(241, 82, 35);
    color: rgb(241, 82, 35);
    box-shadow: 0px 0px 4px;
    height: 10px;
    width: 10px;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 5px;
    margin-right: 3px;
}
.word_break{
    word-break: break-all;
}
/*** Staking Page Pie Chart Start ***/