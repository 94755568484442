:root {
    --primary-color-300: #f15223;
    --system-color-100: #14161a;
    --system-color-600: #ffffff;
    --collapse-bg-color-100: #191c20;
}
[data-theme='light'] {
    --primary-color-300: #f15223;
    --system-color-100: #f4f4f5;
    --system-color-600: #2e2f3c;
    --collapse-bg-color-100: #d3d3d5;
}
/* ------------------------ Variable Define -------------------------------- */
/* Safari specific css start */
.safari .empty_pie_area::before,
.safari .pie_area::before,
.safari .stake_donut_area::before {
    top: 168px !important;
}
.safari .pie_area::before {
    left: 366px !important;
}
@media screen and (min-width: 1400px) {
    .safari .pie_area::before {
        left: 410px !important;
    }
}
@media screen and (max-width: 1079px) {
    .safari .pie_area::before {
        left: 108px !important;
    }
}
@media screen and (max-width: 991px) {
    .safari .pie_area::before {
        left: 366px !important;
    }
}
@media screen and (max-width: 768px) {
    .safari .pie_area::before {
        left: 58px !important;
    }
}
@media screen and (max-width: 400px) {
    .safari .empty_pie_area::before,
    .safari .pie_area::before {
        top: 200px !important;
    }
}
@media screen and (max-width: 395px) {
    .safari .stake_donut_area::before {
        top: 200px !important;
    }
}
/* Safari specific css end */


/* Browser media query */
@media (max-width: 1492px) {
    .vrsw_block h1 {
        font-size: 35px;
    }
    .vrsw_report h1 {
        font-size: 20px;
    }
    .info_transactions {
        max-width: 174px !important;
    }
}
@media (max-width: 1440px) {
    .pool_header .coin_name.first_div {
        margin-right: 15px;
    }
    .additionalInfo_Block {
        left: auto;
        margin: 0;
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
        top: auto;
        position: relative;
    }
    .LdPoolsDetails {
        margin-left: 3%;
    }
}
@media (max-width: 1400px) {
    .trades_table::after {
        width: 93.5%;
    }
    .info_transactions {
        margin-top: 15px;
    }
    .info_pools_listing {
        max-width: 90% !important;
    }
    .pools_list {
        max-width: 100% !important;
    }
}
@media (max-width: 1366px) {
    .info_transactions {
        margin-top: 0;
    }
    .pool_currency_area {
        max-width: 147px;
    }
    .info_transactions {
        max-width: 123px !important;
    }
    .graph_title h2 {
        font-size: 16px;
    }
    .LdDeposits_inner_area.Featured_cards.vrsw_report .row {
        margin-bottom: 0px !important;
    }
    .vrsw_report h1 {
        font-size: 14px;
    }
    .Ld_vrsw_example p {
        margin-bottom: 1.05rem !important;
    }
    .trades_table::after {
        width: 93.5%;
    }
}
@media (max-width: 1280px) {
    .inner_area.routePage {
        max-width: 100%;
    }
    .routing-tab .first h6 {
        font-size: 11px;
    }
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 1110px;
    }
    .filter_dropdown .custom_btn {
        padding: 12px 20px;
    }
    .navbar-nav .nav-link {
        padding: 6px 15px !important;
    }
    .token_block.routeBig {
        max-width: none;
    }
    .info_transactions {
        margin-top: 10px;
    }
    .graph_title h2 {
        font-size: 16px;
    }
    .pool_currency_area h1 {
        font-size: 20px;
    }
    .pool_header .coin_name.first_div {
        margin-right: 12px;
        padding-left: 15px;
    }
    .pool_header .pool_details {
        width: 190px;
    }
    .inner_area.routePage .trade_info {
        display: block;
    }
    .token_block.routeBig {
        margin: 20px auto;
    }
    .bit_inside p {
        margin-left: 30px;
    }
    .bit_inside.arrowImg1 {
        right: -155px;
    }
    .LdPoolsDetails .block3 .add_dialogue {
        left: auto;
        margin: 0;
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
        top: auto;
        position: relative;
    }
    .LdPoolsDetails {
        margin: 0 auto;
    }
    .Switch_Network .custom_btn {
        font-size: 13px;
    }
}
@media (max-width: 1147px) {
    .Switch_Network .custom_btn {
        font-size: 10px;
        white-space: pre;
    }
    .dropdown_block_size {
        width: auto;
        max-width: 180px;
        font-size: 12px;
    }
    .navbar-nav .nav-link {
        font-size: 12px;
    }
}
@media (max-width: 1080px) {
    .pie_area::before {
        left: 118px;
        top: 161px;
    }
}
@media (max-width: 1024px) {
    .trades_table::after {
        width: 93%;
    }
    .pool_header .pool_menus {
        flex-wrap: wrap;
    }
    .pool_header .pool_data .coin_name p {
        font-size: 12px;
    }
    .pool_header .pool_details {
        width: 170px;
    }
    .pool_header .coin_name.first_div p {
        font-size: 15px;
    }
    .back_to_pool img {
        margin-right: 12px;
    }
    .pool_currency_area {
        max-width: 129px;
    }

    .tab_area .nav-tabs .nav-link {
        padding: 4px 6px;
    }
    .p_bal_title h2 {
        font-size: 19px;
    }
    .inner_area {
        max-width: 90%;
    }
    .inner_area.routePage .trade_info {
        display: block;
    }
    .token_block.routeBig {
        margin: 20px auto;
    }
}
@media (max-width: 991px) {
    /* .pie_area::before {
        top: 102px;
    } */
    .Check-eligibility {
        margin-top: 20px;
    }
    .Check-eligibility .LdClaim {
        width: 20%;
    }
    .AddLiquidity_block {
        margin: 0 auto;
    }
    .PoolReservesBlock {
        max-width: 450px;
        margin: 0 auto;
        margin-top: 20px;
    }
    .PoolResBlock {
        padding: 10px !important;
    }
    .plReserve_Block.pool_details::after {
        top: 369px;
        left: 22px;
        width: 90%;
    }
    .PoolReservesAdditionalBlock {
        width: 35%;
        margin: 20px auto;
    }
    .filter_dropdown {
        justify-content: space-between;
    }
    .pool_balance_table {
        margin-top: 30px;
    }
    .pool_header {
        display: block;
    }
    .pool_menu_btn {
        margin-top: 20px;
    }
    .pool_currency_area {
        margin-top: 5px;
        margin-bottom: 5px;
        max-width: 175px;
    }
    .info_transactions {
        max-width: 175px !important;
    }
    .add_dialogue.extra_space {
        margin: 0 30px 30px 30px;
        width: auto;
    }
    .token_block.block4 .flex_title {
        padding: 30px 30px 0 30px;
    }
    .token_block.block4.invest_block {
        padding: 30px;
    }
    .searchbar {
        padding: 0 30px 0 30px;
    }
    .select_search {
        padding-left: 35px;
        padding-right: 35px;
    }
    .add_dialogue {
        left: auto;
        margin: 0;
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
        top: auto;
        position: relative;
    }
    .token_block {
        padding: 30px;
    }
    .dropdown_info {
        /* left: 0; */
        /* top: 100%; */
    }
    .main_content {
        padding: 20px 0;
    }
    .navbar-nav {
        padding: 15px;
        margin-bottom: 0 !important;
    }
    .navbar-collapse {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .filter_dropdown {
        width: 100%;
        border-top: 1px solid #33343b;
        padding-top: 5px;
    }
    .navbar-toggler-icon {
        background-image: none;
    }
    .navbar-toggler-icon i {
        font-size: 21px;
        color: var(--system-color-600) !important;
    }
    .navbar-toggler {
        position: absolute;
        right: 0;
        top: 20px;
    }
    .navbar-toggler:focus {
        outline: none;
        box-shadow: none;
    }
    .navbar-collapse {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 70px;
        box-shadow: 0px 4px 7px rgb(204 72 35 / 38%), 0 6px 6px rgb(0 0 0 / 23%);
        background: var(--collapse-bg-color-100);
        z-index: 9;
    }
    .curr_dropdown_menu ul li a {
        padding: 5px 13px;
    }
    .curr_dropdown_menu ul li a p {
        font-size: 13px !important;
    }
    .filterbar a {
        width: 42px;
        height: 42px;
        line-height: 36px;
    }
    .pool_menu_btn a {
        padding: 5px 10px;
        width: 155px;
    }
    .pool_header .pool_details {
        margin: 10px 10px 10px 0 !important;
    }
    table.dataTable > tbody > tr td:first-child,
    table.dataTable > thead > tr th:first-child {
        padding-left: 10px;
    }
    .pool_header .pool_details {
        width: auto;
    }
    .steps_follow {
        max-width: 630px;
    }
    .Lock_Period_boost .bonus_range_point1 {
        left: 226px;
    }
    .Lock_Period_boost .bonus_range_point2 {
        left: 457px;
    }
    .Lock_Period_boost .bonus_range_point3 {
        left: 687px;
    }
    .select_theme .multiHops-flex {
        margin-right: 50px;
    }
    .LdMenuOption:hover .nav-link {
        color: var(--primary-color-300);
        transition: 0.3s;
        background: var(--system-color-100);
    }
    .pie_area::before {
        left: 376px;
        top: 158px;
    }
    .responsiveTable .actionsEvents .action_btns {
        flex-wrap: wrap;
        grid-gap: 10px;
        justify-content: flex-start !important;
    }
    .responsiveTable .actionsEvents .action_btns.justify-content-center {
        justify-content: center !important;
    }
}
@media (max-width: 990px) {
    .graph_tab_area {
        margin-bottom: 20px;
    }
    .data_table_list {
        margin-top: 0;
    }
    .pool_balance_table {
        margin-top: 20px;
    }
    .profileDropDown {
        position: relative;
        /* right: 40px; */
    }
    .navbar-expand-lg .Connect_wallet {
        position: relative;
    }
    .LdMenuOption:hover .LdSubMenu {
        transform: translate(20%, 62%);
        top: 50%;
        left: 5%;
    }
    .voting_power_blocks {
        margin-top: 15%;
    }
}
@media (max-width: 820px) {
    .token_block.routeBig .wallet_desc {
        width: 400px;
    }
    .single-center{
        max-width: 330px;
    }
    .pool_info.triangle {
        margin-bottom: 20px;
    }
}
@media (max-width: 768px) {
    .token_block.routeBig .wallet_desc {
        max-width: 400px;
        width: 100%;
    }

    .token_block.routeBig .wallet_desc {
        max-width: 300px;
        width: 100%;
    }

    .pool_info {
        display: flex;
        flex-direction: column;
    }

    .img_comb {
        margin: 10px 0;
    }
    .withdraw_curr .img_comb {
        margin: 0;
        margin-right: 10px;
    }
    .bit_inside p {
        border-radius: 7px;
        padding: 5px 15px 5px 15px;
        font-size: 12px;
    }

    .bit_inside.arrowImg {
        position: absolute;
        left: -145px;
        top: 15px;
    }

    .bit_inside.arrowImg1 {
        right: -125px;
        top: 5px;
    }

    .pool_info {
        text-align: center;
    }
    .tab_area.second_tab .nav-tabs {
        margin-top: 10px;
    }

    .buy_VRSW {
        margin-top: 10px;
    }
    .comingSoonBanner {
        padding: 50px;
    }
    .appLoad img {
        width: 100%;
        min-width: 150px;
    }
    .img_comb.LdDepSubmit,
    .img_comb.select_pair {
        margin: 0;
        margin-right: 10px;
    }
    .confirm_UnStake .slideOptionSelection {
        flex-wrap: wrap;
        padding-top: 2px;
    }
    .confirm_UnStake .rangeOption {
        margin-top: 10px;
        margin-right: 2px;
    }
    .popular_Tokens .priceTag {
        width: calc(50% - 10px);
        margin-bottom: 10px;
        box-sizing: border-box;
    }
    .stake_block_align_VRSW {
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
    }
    .pie_area::before {
        left: 68px;
        top: 159px;
    }
    .Featured_Numbers .return_on_Featured .col {
        flex-basis: calc(100% - 20px);
        margin: 0 0 20px 0;
    }
    /* .pie_area .apexcharts-legend {
        width: auto; 
        max-height: none !important; 
    } */
    /* .pie_area .apexcharts-legend .apexcharts-legend-series  {
        display: block !important;
        margin-bottom: 5px; 
    } 
    .pie_area .apexcharts-legend-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    .pie_area .apexcharts-legend {
        width: auto;
    } */

    .pie_area .apexcharts-legend {
        width: 200px;
    }
    .triangleBlocks{
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 750px) {
    .steps_follow {
        display: block;
    }
    .step_block {
        margin: 0 auto 20px;
    }
    /* .step_block::after {
    display: none;
  } */
    .step_block::after {
        content: '';
        border: 1px dashed #33343b;
        display: block;
        position: absolute;
        left: 50%;
        height: 22px;
        z-index: -1;
        transform: translateX(-50%);
        top: 100%;
        width: 0;
    }
    .Ld_head_title {
        display: inline-block;
    }
    .Ld_head_title h1 {
        margin-bottom: 10px;
    }
}
@media (max-width: 700px) {
    .new_table_block .apr_percentage .apr_title {
        font-size: 14px !important;
    }
    .new_table_block .PositionLpToolTip {
        font-size: 13px !important;
    }
}
@media (max-width: 641px) {
    .new_table_block .actionsEvents.pivoted .action_btns,
    .custom-Boosted-tooltip .cellBlocks.pivoted .PositionLpToolTip {
        justify-content: flex-start !important;
    }
    .new_table_block .responsiveTable tbody tr td,
    .new_table_block .responsiveTable thead tr th {
        padding-bottom: 15px;
    }
    .new_table_block .actionsEvents.pivoted {
        padding: 0 !important;
        padding-top: 25px !important;
    }
    .new_table_block .custom-Boosted-tooltip .new_lp_position {
        margin-left: 0 !important;
    }
    .new_lp_position .assetsTitle{
        margin-left: 4px;
        word-break: break-all;
    }
    .single-center{
        max-width: 230px;
    }
}
@media (max-width: 520px) {
    .new_table_block .responsiveTable tbody tr td.pivoted {
        padding-left: calc(60% + 10px) !important;
    }
    .responsiveTable tbody tr td.pivoted.actionsEvents {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .new_table_block .responsiveTable tbody tr td.pivoted.actionsEvents {
        padding: 0 !important;
        padding-top: 25px !important;
    }
    .arrowBox p {
        padding: 5px;
        text-align: center;
    } 
    .token_block.routeBig {
        padding: 10px !important;
    }
}
@media (max-width: 576px) {
    .filter_dropdown {
        padding-top: 10px;
        padding-bottom: 4px;
    }
    .header_block {
        flex-wrap: wrap;
    }
    .token_block.routeBig .wallet_desc {
        max-width: 50%;
    }
    .step_block {
        margin: 0 auto;
        margin-bottom: 10px;
        width: 100%;
        max-width: 100%;
    }
    .step_block:last-child {
        margin-bottom: 0;
    }
    .virtual_block {
        padding: 0px 10px 20px 10px;
    }
    .steps_follow {
        display: block;
        max-width: 100%;
    }
    /* .step_block::after {
    display: none;
  } */
    .pool_header {
        display: block;
    }
    .pool_menu_btn {
        margin-top: 10px;
    }
    .pool_currency_area {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .pool_content_blocks {
        padding: 15px 20px;
    }
    .main_content {
        padding: 15px 0;
    }
    .graph_tab_area {
        margin-bottom: 20px;
    }
    /* .modal .modal-dialog {
        margin: 20px auto;
    } */
    .stepBlock_btn {
        display: block;
    }
    .stepBlock_btn a:first-child {
        margin-bottom: 10px;
    }
    .stepBlock_btn.more_margin {
        margin-top: 22px;
    }
    .total_pay {
        margin-bottom: 22px;
    }
    .more_mg {
        margin-top: 22px;
    }
    .stepBlock_btn span:first-child {
        margin-bottom: 10px;
    }
    .investCancel {
        margin-right: 0px;
    }
    .select_theme {
        /* width: 100%;
    justify-content: flex-end !important; */
        /* margin-right: 35px; */
    }
    .navbar-collapse {
        top: 113px;
    }
    .select_theme .multiHops-flex {
        margin-right: 0;
    }
    header .logo_bar {
        width: 100%;
    }
    .navbar > .container {
        justify-content: flex-end;
    }
    .PoolReservesAdditionalBlock {
        width: 60%;
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .TransactionSettingModal .modal-content {
        margin: 10px;
    }
    .stepBlock_btn .btn:first-child {
        margin-bottom: 10px;
    }
    .TransactionSettingModal .modal-header {
        padding: 1rem;
    }
    .UnStackLpTokensModal .modal-dialog-centered {
        margin: 1.75rem auto;
    }
}
@media (max-width: 500px) {
    .stakeDonutGraph .row {
        display: flex;
        flex-direction: column;
    }
    .stakeDonutGraph .row > div {
        width: 100% !important;
        flex: inherit !important;
    }
    .donutChart_legend {
        max-height: 120px !important;
    }
    .inner-left-three {
        margin-right: 10px;
    }
    .inner-left-three.right {
        margin-right: 0;
        margin-left: 10px;
    }
    .inner-left-three span {
        font-size: 10px;
    }
}
@media (max-width: 480px) {
    .dropdown_info {
        width: 250px;
    }
    .graph_status p {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 70px;
        padding: 5px;
    }
    .bit_inside.arrowImg {
        position: absolute;
        left: -112px;
        top: 10px;
    }

    .bit_inside.arrowImg1 {
        right: -80px;
        top: 10px;
    }
    .Toastify__toast-container.Toastify__toast-container--top-right {
        top: 1em;
        right: 1em;
        left: auto;
        width: 80% !important;
    }
    .Toastify__toast{
        margin-bottom: 10px !important;
    }
}
@media (max-width: 414px) {
    .btn.btn-dark {
        font-size: 16px;
        width: 140px;
        padding: 7px 7px;
    }
    .block4 .all_tabs {
        margin-bottom: 20px;
    }
    .step_information {
        margin-top: 20px;
    }
    .wallet_dropdown p.red_text {
        right: 0;
        bottom: -18px;
    }
    .pool_info {
        padding: 12px;
    }
    .routing-tab .first .first-inner {
        padding: 8px 9px;
    }
    .token_block.routeBig {
        max-width: 100%;
    }
    .token_block.routeBig .wallet_desc {
        width: 100%;
    }
    .virtual_block h1 {
        padding: 20px 0 20px 0;
    }
    .namedrop .btn-select {
        width: 145px !important;
    }
    .navbar-brand {
        font-size: 16px;
    }
    .navbar-brand img {
        margin-right: 9px;
    }
    .filter_dropdown {
        flex-wrap: wrap;
    }
    .filterbar {
        margin-top: 10px;
        margin-left: 0;
    }
    header .logo_bar {
        display: block;
    }
    header .logo_bar form,
    header .logo_bar {
        width: 100%;
    }
    header .logo_bar form input {
        margin: 5px 0 2px 0;
    }
    h1 {
        font-size: 20px;
    }
    .trade_page.filterbar {
        margin-top: 0;
        margin-left: 10px;
    }
    .c-modal-sm {
        max-width: 90%;
        margin: 20px auto;
    }
    .pool_info {
        display: block;
    }
    .img_comb {
        margin-bottom: 8px;
    }
    .saving_block {
        display: block;
        text-align: center;
    }
    .saving_block p:first-child {
        border-right: 0;
    }
    .c-modal-xs {
        max-width: 90%;
    }
    /* .modal .modal-dialog {
        max-width: 90%;
    } */
    .transaction-modal-header {
        padding: 25px 25px 15px 25px;
    }
    .transaction-modal-pad-1 {
        padding: 0 25px;
    }
    .interface-settings-wrapper {
        padding: 24px 25px 25px;
    }
    .filter_dropdown {
        padding-top: 8px;
    }
    .filter_dropdown .custom_btn {
        padding: 8px 10px;
        font-size: 13px;
    }
    .dropdown_info {
        /* left: 0; */
        width: 250px;
    }
    .coin_name .img_comb {
        margin-bottom: 0;
    }
    .tradePageSet {
        width: 100%;
        min-width: 100% !important;
    }
    .navbar-expand-lg .Connect_wallet {
        right: 0;
    }
    .UnStackLpTokensModal .modal-dialog-centered .modal-content {
        margin: 10px;
    }
    .Toastify__toast-container.Toastify__toast-container--top-right {
        width: 85% !important;
    }
}
@media (max-width: 405px) {
    .info_pools_listing .checkGraph.graph_title {
        justify-content: flex-end !important;
    }
}
@media (max-width: 395px) {
    .pie_area::before {
        left: 68px;
        top: 191px;
    }
    .empty_pie_area::before {
        top: 190px;
    }
    .stake_donut_area::before {
        top: 190px;
    }
}
@media (max-width: 375px) {
    .form-group.checklist input:checked + label:after {
        top: 10px;
    }
    p,
    table.dataTable td {
        font-size: 12px;
    }
    .graph_title {
        display: block;
    }
    .back_to_pool {
        border-right: 0;
    }
    .pool_header .coin_name.first_div {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .tab_area .nav-tabs {
        margin-top: 10px;
    }
    .slippage-tolerance-value .first {
        flex-wrap: wrap;
    }
    .first .input-pos-wrapper input {
        margin: 0;
        width: 100%;
    }
    .input-pos-wrapper {
        margin-top: 10px;
    }
    .customValue .input-pos-wrapper {
        margin: 0;
    }
    .token_block.block4 .flex_title {
        padding: 15px 15px 0 15px;
    }
    .token_block.block4.invest_block {
        padding: 15px;
    }
    .searchbar {
        padding: 0 15px 0 15px;
    }
    .block4 .stepBlock_btn {
        padding: 0 15px 15px 15px;
    }
    .list_types {
        padding: 0 15px;
    }
    .token_block {
        padding: 15px;
    }
    .transaction-frontrun h6 {
        text-align: left;
    }
    .wallet_value h2 {
        font-size: 20px;
    }
    .percent-pos {
        right: 12px;
    }
    .second {
        padding-left: 10px;
        padding-top: 10px;
    }
    .transaction-modal-body .stake-block {
        font-size: 22px !important;
    }
    .info_pools_listing .checkGraph.graph_title {
        justify-content: space-between !important;
    }
}
@media (max-width: 320px) {
    .pool_currency_area {
        max-width: 115px;
    }
    .namedrop .btn-select {
        width: 140px !important;
    }
    .desc_pop img {
        margin-left: 6px;
    }
}
@media (max-width: 415px) {
    .profileDropDown {
        right: 0px;
    }
}
@media (max-width: 380px) {
    .single-center.middle-one {
        max-width: calc(230px - 20px);
    }
}
@media (max-width: 370px) {
    .single-center.middle-one {
        max-width: calc(160px - 20px);
    }
    .single-center {
        max-width: 140px;
    }
}
@media (max-width: 350px) {
    .priceTag {
        margin-right: 6px;
    }
    .graph_status p {
        font-size: 10px;
        min-width: 60px;
    }
}
@media screen and (max-width: 40em) {
    .responsiveTable td .tdBefore {
        top: 0;
        bottom: 0;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }
}
@media (min-width: 1400px) {
    .pie_area::before {
        left: 421px;
    }
}
